import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios"
import createAuthRefreshInterceptor from "./axios-auth-refresh"
import { message } from "antd"
import toast from "./toast"
import { getHost, sanitizeHeader, timeout } from "./helper"
import { endpoints } from "config/endpoints.config"
import envs from "config/envs.config"

export interface IEnv {
  url: string
  socketUrl: string
  socketUrlB: string
  v2SocketUrl: string
  v1Url: string
  v2Url: string
  widgetUrl: string
  key: "production" | "staging" | "dev" | "mock" | "mock-error" | string
}

export type IRequestMethods = "post" | "get" | "put" | "patch" | "delete" | string

// Get Domain from LocalStorage
let axiosInstance: AxiosInstance
let _defaultEnv: IEnv
let _history: any
let loggingOut = false

export const getEnv = () => {
  try {
    return JSON.parse(localStorage.getItem("nanui-env")!) || _defaultEnv || {}
  } catch (e) {
    return _defaultEnv || {}
  }
}
// Function that will be called to refresh authorization
// const refreshAuthLogic = (failedRequest: any, onRefreshedToken: any) =>
//     axiosInstance
//         .post(_renewTokenEndpoint, {
//             token: localStorage.getItem("nanui-token"),
//             renewalToken: localStorage.getItem("nanui-token-renewal"),
//         })
//         .then((tokenRefreshResponse) => {
//             // console.log('tokenRefreshResponse', tokenRefreshResponse);
//             // localStorage.setItem('nanui-token', token);
//             // localStorage.setItem('nanui-token-renewal', renewalToken);
//             const [token] = onRefreshedToken(tokenRefreshResponse.data)
//             failedRequest.response.config.headers.Authentication = token
//             return Promise.resolve()
//         })

// Print requests on devtools
function printRequest({ body, params, received, method, url }: any) {
  const subdomain = getHost().subdomain
  const userString = localStorage.getItem("user")
  let user
  try {
    user = JSON.parse(userString || "")
  } catch (error) {
    console.log(error)
  }

  if (
    (!(import.meta.env?.PROD && import.meta.env.VITE_ENV !== "staging") && (subdomain != "staging" || user?.id == "1")) ||
    subdomain == "view" ||
    user?.isSystemAdmin
  ) {
    // if (getHost()?.subdomain == "view") {
    console.group(`%c[${method.toUpperCase()}] ` + url, "color: #f1c40f; background-color: black")
    // console.log("Response: ", response)
    console.log(
      "Enviado: ",
      body || "{}",
      // params || ''
    )
    console.log("Recebido: ", received || "Erro de conexão. Sem contato com servidor")
    console.groupEnd()
  }
}
// function printRequest(response: Response, { method, data, params, body }: any) {
//     console.group(
//         `%c[${method.toUpperCase()}] ` +
//         response.url,
//         "color: #f1c40f; background-color: black"
//     )
//     console.log("Response: ", response)
//     console.log(
//         "Enviado: ",
//         JSON.parse(body || "{}"),
//         params || ''
//     )
//     console.log(
//         "Recebido: ",
//         data || "Erro de conexão. Sem contato com servidor"
//     )
//     console.groupEnd()
// }

function getAccessToken() {
  return localStorage.getItem("nanui-token")
}

/*
 * OnRefreshed: Callback to update token on localStorage: eg: () => localStorage.setItem('token', tokenRefreshResponse.data.token);
 * getAccessToken: Callback to always get updated token from localStorage; eg: () => ;
 */
// export function initRequest({
//     defaultEnv,
//     mock,
//     renewTokenEndpoint,
//     onRefreshedToken,
//     isProduction,
// }) {
interface IInitRequest {
  defaultEnv: IEnv
  // mock: IMock
  // renewTokenEndpoint: string
  // onRefreshedToken: Function
  // isProduction: boolean
  history: any
  // subdomain: string
}

export function initRequest(props: IInitRequest) {
  _history = props.history
  // _mock = props.mock
  // _renewTokenEndpoint = props.renewTokenEndpoint
  _defaultEnv = props.defaultEnv
  // _isProduction = props.isProduction

  // Create Axios Instance
  // axiosInstance = axios.create({
  //     baseURL: getDomain(),
  //     headers: { "Content-Type": "application/json" },
  // })

  // Create renewal of token logic
  // createAuthRefreshInterceptor(
  //     axiosInstance,
  //     (failedRequest: any) =>
  //         refreshAuthLogic(failedRequest, props.onRefreshedToken),
  //     {
  //         statusCodes: [403],
  //     }
  // )

  // axiosInstance.interceptors.request.use((request) => {
  //     const token = getAccessToken()

  //     if (token) {
  //         request.headers["Authorization"] = token
  //     }
  //     return request
  // })

  // Handle Response
  // axiosInstance.interceptors.response.use(
  //     (response) => {
  //         if (!_isProduction) {
  //             printRequest(response)
  //         }
  //         return response
  //     },
  //     (error) => {
  //         if (error.response) {
  //             printRequest(error.response)
  //             try {
  //                 if (error.response.status != 403) {
  //                     toast.error(error.response.data.errors[0].descriptions[0])
  //                 }
  //             } catch (e) {
  //                 toast.error("Erro desconhecido no servidor")
  //             }
  //             throw error
  //         } else if (error.request) {
  //             console.error(error, error.request)
  //             toast.error("Sem resposta do servidor")
  //             // printRequest({config: { method: '', }});
  //         } else {
  //             toast.error("Erro desconhecido")
  //             console.error(error)
  //         }
  //         return Promise.reject(error)
  //     }
  // )

  return axiosInstance
}

/*
 * Returns .get, .post, .patch, .put, .post, .save, .axiosInstance
 */
function send<T>(method: IRequestMethods = "GET", url: string, body: any, config?: any, localConfig?: ILocalConfig): Promise<any> {
  let env: IEnv = getEnv()
  // let domain = getDomain()?.toLowerCase()
  let Authorization = getAccessToken() || ""
  let user
  let workspace

  // function generateRandomZeroOrOne() {
  //   return Math.round(Math.random());
  // }

  // const seed = generateRandomZeroOrOne()
  // if (seed == 1) {
  //     // https://staging-api.kinbox.com.br/api
  //     domain = "https://kinbox-api-v0-bk2-ev2aq.ondigitalocean.app/api"
  // }

  // console.log("domain", domain)
  // NEW API DOMAIN
  // let env: IEnv | null = null
  let isV1
  let domain

  try {
    env = JSON.parse(localStorage.getItem("nanui-env")!)
    // domain = "https://kinbox-api-v0-bk-g6pbb.ondigitalocean.app/api"

    // if (env?.key == "staging" || env?.key == "localhost" || env?.key == "localhost-prod" || env?.key == "custom") {
    //   domain = env?.url
    // }

    const version = url.slice(0, 3)

    switch (version) {
      case "/v1":
        domain = env?.v1Url || envs[1].v1Url
        Authorization = "bearer " + Authorization
        isV1 = true
        break
      case "/v3":
        domain = env?.v2Url || envs[1].v2Url
        Authorization = "bearer " + Authorization
        isV1 = true
        break
      default:
        domain = env?.url || envs[1].url
        break
    }

    // if (version == "/v1") {
    //   isV1 = true
    //   if (!env?.key || env?.key == "production" || env?.key == "localhost-prod") {
    //     domain = "https://kinbox-api-sr7d7.ondigitalocean.app/api"
    //     // domain = "http://localhost:3000/api"
    //   } else if (env?.key == "staging" || env?.key == "localhost") {
    //     domain = "https://staging-api-v1.kinbox.com.br/api"
    //     // domain = "http://localhost:3000/api";
    //   }
    //   Authorization = "bearer " + Authorization
    // }
    // if (version == "/v3") {
    //   isV1 = true
    //   // env = JSON.parse(localStorage.getItem("nanui-env")!)
    //   if (!env?.key || env?.key == "production" || env?.key == "localhost-prod") {
    //     domain = "https://api4.kinbox.com.br"
    //     // domain = "http://localhost:3000"
    //   } else if (env?.key == "staging" || env?.key == "localhost") {
    //     domain = "http://localhost:3000"
    //     // domain = "https://api4-staging.kinbox.com.br"
    //   }
    //   Authorization = "bearer " + Authorization
    // }
    let userString = localStorage.getItem("user")
    let workspaceString = localStorage.getItem("workspace")
    if (userString && workspaceString) {
      user = JSON.parse(userString)
      workspace = JSON.parse(workspaceString)
    }
  } catch (e) {
    console.error(e)
  }

  let res: Response
  let showedErrorToast = false
  // const isStaging = env?.key == "staging"

  if (["1577", "867"].includes(workspace?.workspaceId)) {
    try {
      // if (method.toLocaleLowerCase() == "post" && url === "/inbox/messages") {
      //   domain = domain?.replace("kinbox-api-v0-bk-g6pbb.ondigitalocean.app", "kinbox-api-v0-bk-obs-2-isakv.ondigitalocean.app")
      // } else {
      domain = domain?.replace("kinbox-api-v0-bk-g6pbb.ondigitalocean.app", "kinbox-api-v0-bk-obs-odmpz.ondigitalocean.app")
      // }
    } catch (e) {
      console.log(e)
    }
  }

  const submit = async (count: number) => {
    return fetch(`${domain}${url}`, {
      // return fetch(`https://bcb7-177-190-210-78.ngrok.io/api${url}`, {
      method,
      body: method.toUpperCase() == "GET" ? undefined : body ? JSON.stringify(body) : undefined,
      headers: {
        "Content-type": "application/json",
        "Referrer-Policy": "strict-origin-when-cross-origin",
        "Permissions-Policy": "accelerometer=(), gyroscope=(), magnetometer=(), microphone=(), usb=()",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
        "Content-Security-Policy":
          "default-src 'self'; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline'; img-src https://*; child-src 'none';",
        "X-Frame-Options": "SAMEORIGIN",
        "X-Content-Type-Options": "nosniff",
        // "ngrok-skip-browser-warning": true,
        // ...(isStaging
        //     ? {
        //           "Referrer-Policy": "strict-origin-when-cross-origin",
        //           "Permissions-Policy": "accelerometer=(), gyroscope=(), magnetometer=(), microphone=(), usb=()",
        //           "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
        //           "Content-Security-Policy":
        //               "default-src 'self'; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline'; img-src https://*; child-src 'none';",
        //           "X-Frame-Options": "SAMEORIGIN",
        //           "X-Content-Type-Options": "nosniff",
        //       }
        //     : {}),
        ...(isV1
          ? {
              "x-user-id": user?.id,
              "x-user-email": sanitizeHeader(user?.email),
              "x-ws-id": workspace?.workspaceId,
              "x-ws-name": sanitizeHeader(workspace?.name),
            }
          : {}),
        ...(user?.inspectingUser
          ? {
              "x-inspecting-user-email": sanitizeHeader(user?.inspectingUser),
              "x-inspecting-type": user?.inspectingType,
            }
          : {}),
        Authorization,
        ...localConfig?.headers,
      },
      ...config,
    })
      .then((response) => {
        res = response
        return response.json()
      })
      .then((data) => {
        if (data?.statusCode == 500) {
          throw data
        }
        // try {
        if ((data?.errors?.length || data?.error || data?.statusCode == 500) && !url.includes("/custom-fields")) {
          showedErrorToast = true
          // console.log(111, body, data, method, url)
          try {
            if (data?.error) {
              if (data?.error != "Por favor, faça o login novamente" && !localConfig?.ignoreError) {
                toast.error(
                  // `${localConfig?.errorSuffix ? `${localConfig.errorSuffix}: ` : ""}${data?.error}${data?.message ? `- ${data?.message}` : ""}`
                  `${data?.error}${data?.message ? `- ${data?.message}` : ""}`,
                  5,
                  url == endpoints.PROXY
                    ? {
                        prefix: body?.url?.split("/")?.[2],
                      }
                    : undefined,
                )
              }
            } else if (!localConfig?.ignoreError) {
              // toast.error(`${localConfig?.errorSuffix ? `${localConfig.errorSuffix}: ` : ""}${data?.errors?.[0].descriptions?.[0]}`)
              toast.error(
                `${data?.errors?.[0].descriptions?.[0] || data?.errors?.[0]?.message || "Erro desconhecido"}`,
                5,
                url == endpoints.PROXY
                  ? {
                      prefix: body?.url?.split("/")?.[2],
                      key: res?.url,
                    }
                  : { key: res?.url },
              )
            }
            // if (error.response.status != 403) {
            //     toast.error(error.response.data.errors[0].descriptions[0])
            // }
          } catch (e) {
            showedErrorToast = true
            if (!localConfig?.ignoreError) {
              toast.error(
                "Erro desconhecido no servidor",
                5,
                url == endpoints.PROXY
                  ? {
                      prefix: body?.url?.split("/")?.[2],
                      key: res?.url,
                    }
                  : { key: res?.url },
              )
            }
          }
          // throw new Error(data?.errors?.[0]?.descriptions?.[0])
          throw data
        }
        try {
          printRequest({
            body,
            received: data,
            method,
            url,
          })
        } catch (e) {
          console.log("Erro no printRequest")
        }
        // }
        // catch (e) {
        //     console.log(e)
        //     toast.error("Erro desconhecido no servidor")
        //     throw new Error("Erro desconhecido no servidor")
        // }
        return { data }
      })
      .catch(async (error) => {
        if (localConfig?.propagateError) {
          throw error
        }
        if (loggingOut) {
          return { data: [] }
        }
        if (res?.status == 201) {
          return { data: {} }
        }

        if (res?.status == 401 && !res.url?.includes("operators/login") && !res.url?.includes("proxy/exec-request")) {
          loggingOut = true
          _history?.push?.("/auth/logout")
        }
        if (!localConfig?.ignoreError) {
          if (error?.name == "AbortError") {
            console.log("A requisição foi cancelada")
          } else if (error.message == "Failed to fetch") {
            if (count === 0) {
              console.log("😡😡😡😡😡 Failed to fetch")
              return submit(count + 1)
            }
            if (count === 1) {
              console.log("💩💩💩💩💩 Failed to fetch 2")
              await timeout(2000)
              return submit(count + 1)
            }
            toast.error(
              "Sem contato com o servidor",
              5,
              url == endpoints.PROXY
                ? {
                    prefix: body?.url?.split("/")?.[2],
                    key: res?.url,
                  }
                : { key: res?.url },
            )
          } else if (!res) {
            toast.error(
              "Erro desconhecido",
              5,
              url == endpoints.PROXY
                ? {
                    prefix: body?.url?.split("/")?.[2],
                  }
                : undefined,
            )
          } else if (!showedErrorToast && res?.status == 404) {
            toast.error(
              "Não encontrado (404)",
              5,
              url == endpoints.PROXY
                ? {
                    prefix: body?.url?.split("/")?.[2],
                    key: res?.url,
                  }
                : { key: res?.url },
            )
          } else if (!showedErrorToast) {
            toast.error(
              "Erro no servidor. Status: " + res?.status,
              5,
              url == endpoints.PROXY
                ? {
                    prefix: body?.url?.split("/")?.[2],
                    key: res?.url,
                  }
                : { key: res?.url },
            )
          }
        }
        console.log(error)
        try {
          printRequest({
            body,
            received: error,
            method,
            url,
          })
        } catch (e) {
          console.log("Erro no printRequest")
        }

        throw error
      })
  }

  return submit(0)

  // return axiosInstance({
  //     baseURL: getDomain(),
  //     url,
  //     method,
  //     data,
  //     ...config,
  // })
}

// const request = {
//     // Axios API to send request
//     save: async (endpoint: string, data: any, config?: AxiosRequestConfig) =>
//         send(data.id ? "put" : "post", endpoint, data, config),
//     get: (url: string, config?: AxiosRequestConfig) =>
//         send("get", url, null, config),
//     post: (
//         endpoint: string,
//         data?: any,
//         config?: AxiosRequestConfig
//     ): AxiosPromise<any> => send("post", endpoint, data, config),
//     put: (endpoint: string, data: any, config?: AxiosRequestConfig) =>
//         send("put", endpoint, data, config),
//     patch: (endpoint: string, data: any, config?: AxiosRequestConfig) =>
//         send("patch", endpoint, data, config),
//     delete: (endpoint: string, data?: any, config?: AxiosRequestConfig) =>
//         send("delete", endpoint, data, config),
//     axiosInstance: axiosInstance!,
// }

interface IRequestInitGet extends RequestInit {
  params?: {
    [key: string]: any
  }
}

interface ILocalConfig {
  ignoreError?: boolean
  errorSuffix?: string
  headers?: any
  propagateError?: boolean
}

function post<T = any>(endpoint: string, data?: any, config?: RequestInit, localConfig?: ILocalConfig): Promise<{ data: T }> {
  return send("post", endpoint, data, config, localConfig)
}
function get<T = any>(url: string, config: IRequestInitGet = {}): Promise<{ data: T }> {
  let { params, ...rest } = config
  const keys = Object.keys(params || {})
  let newUrl = url
  if (keys.length) {
    keys.forEach((key) => {
      if (params![key] == null || params![key] == undefined) {
        delete params![key]
      } else if (typeof params![key] != "string") {
        params![key] = params![key] + ""
      }
    })
    newUrl += `?${new URLSearchParams(params!)}`
  }
  return send("get", newUrl, null, rest)
}
function patch<T = any>(endpoint: string, data: any, config?: RequestInit): Promise<{ data: T }> {
  return send("PATCH", endpoint, data, config)
}
function save<T = any>(endpoint: string, data: any, config?: RequestInit): Promise<{ data: T }> {
  return send(data.id ? "put" : "post", endpoint, data, config)
}
function put<T = any>(endpoint: string, data: any, config?: RequestInit): Promise<{ data: T }> {
  return send("put", endpoint, data, config)
}
function remove<T = any>(endpoint: string, data?: any, config?: RequestInit): Promise<{ data: T }> {
  return send("delete", endpoint, data, config)
}

const request = {
  // Axios API to send request
  save,
  get,
  post,
  put,
  patch,
  delete: remove,
  // axiosInstance: axiosInstance!,
}

export default request
