import { endpoints } from "config/endpoints.config";
import { IGenericModel, generic } from "models/generic";

export interface Presence {
  id: string;
  _id: string;
  createdAt: string;

  connectedAt: string;
  operatorName: string;
  disconnectedAt: string;
  operatorId: number;
  groupIds: number[];
  secondsOnline: number;
}

export interface PresenceBlock {
  id: string;
  _id: string;
  createdAt: string;
  startBlockAt?: Date
  operatorIds: number[]
  blockNumber: number
}

export const presence: IGenericModel<Presence> = {
  ...generic(endpoints.REPORT_PRESENCE),
};
