import { getHost } from "lib/helper"
import molaImg from "assets/images/logos/logo_mola.png"
import portImg from "assets/images/logos/logo_port_green.webp"
import LogoSvg from "assets/images/logos/logo_flat.svg?react"

function AuthLogo() {
  const { subdomain } = getHost()

  if (subdomain == "mola" || subdomain == "mola2") {
    return (
      <div className="md:fixed md:top-7 md:left-10">
        <LogoSvg className="size-16 md:size-10 [&_path]:fill-[var(--primarySkin)]" />
        <img src={molaImg} alt="" className="ml-2 w-10 -mt-9" />
      </div>
    )
  }

  if (subdomain == "port" || subdomain == "port2") {
    return (
      <div className="md:fixed md:top-7 md:left-10 rounded-full p-1 bg-[#323b5c] w-20 h-20 flex items-center justify-center -ml-4">
        <img src={portImg} alt="" className="w-16 md:w-12" />
      </div>
    )
  }

  return <LogoSvg className="size-16 md:fixed md:top-7 md:left-10 md:size-10" />
}

export default AuthLogo
