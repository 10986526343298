import { Form, Spin } from "antd"
import { isMobileAtom, modalState } from "atoms/app.atom"
import { useGenericStore, useStoreActions } from "hooks"
import { Workflow } from "models/workflows"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FlowElement, ReactFlowProvider } from "react-flow-renderer"
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom"
import { useRecoilState, useSetRecoilState } from "recoil"
import { mountWorkflowModel, updateElementAction, workflowInitialElements } from "./workflow.helper"
import {
  editWorkflowNodeIdAtom,
  receivedWebhookMonitorAtom,
  searchVisibleAtom,
  workflowElementsAtom,
  workflowVariablesAtom,
} from "atoms/workflow.atom"
import toast from "lib/toast"
import { twMerge } from "tailwind-merge"
import styles from "./style.module.css"
import WorkflowHeader from "./WorkflowHeader"
import { History } from "stateshot"
import WorkflowCanva from "./WorkflowCanva"
import { IAuditLog } from "models/audit"
import BottomRightPanel from "./panels/BottomRightPanel"
import BottomMidPanel from "./panels/BottomMidPanel"
import BaseEditAction from "./actions/BaseEditAction"
import { debounce } from "lodash"
import { WorkflowActionsItem, WorkflowDefs, WorkflowNodeData } from "./workflow.defs"
import { WorkflowTriggerItem } from "./actions/workflow.triggers.defs"
import useLocalStorage from "react-use/lib/useLocalStorage"
import { ActionEnum } from "./workflow.enums"
import queryString from "query-string"

interface EditWorkflowProps {
  children: React.ReactNode
  onSubmitSuccess?: (response: any) => void
}

export let workflowHistoryState: History<any> | null
export let workflowNotSaveHistory = false
export let workflowShouldAnimateNode: any = true
export let setWorkflowShouldAnimateNode = (v) => {
  workflowShouldAnimateNode = v
}
export let setSaveWorkflowHistory = (v) => {
  workflowNotSaveHistory = v
}

export let workflowElementsObj: Record<string, FlowElement<WorkflowNodeData> & { position: { x: number; y: number } }> = {}

function EditWorkflow(props: EditWorkflowProps) {
  const [changed, setChanged] = useState(false)
  const alreadyCentered = useRef<any>(false)
  const [form] = Form.useForm()
  const [isMobile] = useRecoilState(isMobileAtom)
  const [item, setItem] = useState<Workflow | null>(null)
  const [elements, setElements] = useRecoilState(workflowElementsAtom)
  const [itemFromVersion, setItemFromVersion] = useState<Workflow | null>(null)
  const { loading, all } = useGenericStore((state) => state.workflows)
  const [goToPosition, setGoToPosition] = useState<any>()
  const [goToPositionInstant, setGoToPositionInstant] = useState<any>()
  const [unblocked, setUnblocked] = useState(false)
  const [saveHistoryToggler, setSaveHistoryToggler] = useState(false)
  const { save } = useStoreActions((actions) => actions.workflows)
  const [showRunDrawer, setShowRunDrawer] = useState(false)
  const [editNodeId, setEditNodeId] = useRecoilState(editWorkflowNodeIdAtom)
  const setReceived = useSetRecoilState(receivedWebhookMonitorAtom)
  const setModal = useSetRecoilState(modalState)
  const location = useLocation()

  const [fullscreen, setFullscreen]: any = useLocalStorage("workflow_fullscreen", true)
  const setWorkflowVariables = useSetRecoilState(workflowVariablesAtom)
  const { id } = useParams<any>()
  const history = useHistory()
  const spinning = loading.all || loading.one || (id != "new" && !item?.id)

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (query.cloneFromId) {
      const itemInAll = all.find((x: any) => x.id == query.cloneFromId)
      if (itemInAll) {
        const { _id, ...newItem } = itemInAll
        const trigger = newItem?.elements?.find((x: any) => x.type === ActionEnum.TRIGGER) as any
        const newTrigger = { ...trigger } as any
        newTrigger.data.action.triggers = {}
        setItem({
          active: false,
          name: `${newItem.name} (cópia)`,
          elements: [newTrigger, ...newItem.elements.filter((x: any) => x.type !== ActionEnum.TRIGGER)],
          lastNodeId: itemInAll.lastNodeId,
        } as any)
        setChanged(true)
        setTimeout(() => {
          setChanged(true)
        }, 400)
      }
    }
  }, [])

  const editNode = useMemo(() => {
    if (!editNodeId) return undefined
    // return workflowElementsObj[editNodeId]
    return elements.find((el) => el.id == editNodeId)
  }, [editNodeId, elements])

  const undoActions = useMemo(() => {
    return {
      undo: () => {
        const lastState = workflowHistoryState?.undo().get()
        workflowNotSaveHistory = true
        setElements(lastState.elements)
      },
      redo: () => {
        const lastState = workflowHistoryState?.redo().get()
        workflowNotSaveHistory = true
        setElements(lastState.elements)
      },
      canRedo: workflowHistoryState?.hasRedo,
      canUndo: workflowHistoryState?.hasUndo,
    }
  }, [elements, setElements, saveHistoryToggler])

  const itemFromStore: Workflow | undefined = useMemo(() => {
    const item = all.find((x) => x.id == id)
    return item
  }, [all, id])

  useEffect(() => {
    workflowShouldAnimateNode = true
    workflowHistoryState = new History({
      maxLength: 30,
      onChange: (e) => {
        setSaveHistoryToggler((s) => !s)
      },
    })
    document?.body?.classList?.add?.("overflow-hidden")
    return () => {
      document?.body?.classList?.remove?.("overflow-hidden")
      setElements([])
      workflowHistoryState = null
      setWorkflowVariables([])
      setEditNodeId("")
      setReceived(undefined)
    }
  }, [])

  // Auto chamar select trigger modal
  // useEffect(() => {
  //   if (id === "new") {
  //     const triggerNode = elements[0]
  //     setModal(
  //       <SelectTriggerModal
  //         onSelect={(trigger) => {
  //           updateElementAction(
  //             triggerNode?.id,
  //             (oldAction) => ({
  //               ...oldAction!,
  //               triggers: {
  //                 ...oldAction?.triggers!,
  //                 [trigger]: {
  //                   active: true,
  //                 },
  //               },
  //             }),
  //             { setElements }
  //           )

  //           setTimeout(() => {
  //             setEditNodeId(triggerNode?.id)
  //           }, 100)

  //           setModal(null)
  //         }}
  //         onCancel={() => {
  //           setModal(null)
  //         }}
  //       />
  //     )
  //   }
  // }, [])

  useEffect(() => {
    if (!itemFromStore && !itemFromVersion) {
      return
    }
    try {
      const copy = itemFromVersion || itemFromStore
      if (copy) {
        setItem(copy!)

        // if (goToPosition != true) {
        //   const initNode: any = copy.elements?.find((elem) => elem.type == "trigger")
        //   setTimeout(() => {
        //     setGoToPosition({
        //       x: initNode?.position?.x * -1 + window.innerWidth / 2 - 120,
        //       y: initNode?.position?.y * -1 + (window.innerHeight - 200) / 2,
        //       zoom: 1,
        //     })
        //   }, 200)
        // }
      }
    } catch (e) {
      console.log(e)
      setItem(null)
    }
  }, [itemFromStore, itemFromVersion])

  useEffect(() => {
    setChanged(true)
    form.setFieldsValue({ elements })
  }, [elements, form])

  // Build variables Context
  useEffect(() => {
    setWorkflowVariables((v) => {
      v = []
      workflowElementsObj = {}
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index]
        workflowElementsObj[element.id] = element as any

        let action: WorkflowActionsItem
        if (element.type === ActionEnum.TRIGGER) {
          const triggersObj = element?.data?.action?.triggers || {}
          const activeTriggers: WorkflowTriggerItem[] = Object.keys(triggersObj).map((key) => WorkflowDefs.trigger.TriggerRecord[key])

          activeTriggers?.forEach((triggerRecord) => {
            if (triggerRecord?.variableCategory) {
              v.push({
                nodeId: element.id,
                nodeType: triggerRecord.variableCategory,
              })
            }
          })
          // action = WorkflowDefs.ActionRecord[element.type!] as WorkflowActionsItem
        } else {
          action = WorkflowDefs.ActionRecord[element.type!] as WorkflowActionsItem
          if (action?.variableCategory && action?.isReferenceable) {
            v.push({
              nodeId: element.id,
              nodeType: action.variableCategory,
            })
          }
        }
      }

      return v
    })
  }, [elements?.length, elements?.[0]?.data?.action?.triggers])

  useEffect(() => {
    if (item) {
      if (item.elements) {
        const initNode: any = item.elements?.find((elem) => elem.type == "trigger")

        if (!alreadyCentered.current && initNode) {
          setGoToPositionInstant({
            x: initNode?.position?.x * -1 + window.innerWidth / 4 - 120,
            y: initNode?.position?.y * -1 + (window.innerHeight - 200) / 4,
            zoom: 1,
          })
          alreadyCentered.current = true
        }
        setElements(item.elements)
        workflowHistoryState?.reset()
      }
      form.resetFields()

      setTimeout(() => {
        workflowShouldAnimateNode = false
        setReceived(form.getFieldValue(["elements", 0, "data", "action", "triggers", "webhook_inbound", "schema"]))
      }, 400)
      if (!itemFromVersion) {
        setTimeout(() => {
          setChanged(false)
        }, 100)
      }
    } else {
      if (id == "new") {
        setElements(workflowInitialElements)
        alreadyCentered.current = true
      }
    }
  }, [item, form, id])

  const onOpenHistoryVersion = useCallback(
    (version: IAuditLog) => {
      if (version?.data?.body) {
        setGoToPosition(false)
        setItemFromVersion(version?.data?.body)
      } else {
        toast.error("Não foi possível carregar a versão")
      }
    },
    [setItemFromVersion]
  )

  const syncElements = useCallback(() => {
    setTimeout(() => {
      setElements(form.getFieldValue(["elements"]))
    }, 100)
  }, [form, setElements])

  const syncElementsOnValuesChangeDebounced = useMemo(
    () =>
      debounce(
        (newElements) => {
          setElements(newElements)
        },
        300,
        {
          maxWait: 2000,
        }
      ),
    [setElements]
  )

  // console.log("elements", elements)

  return (
    // <RelativePortal left={0} top={0}>
    //@ts-ignore
    <ReactFlowProvider>
      <Prompt when={changed && !isMobile} message="Você tem mudanças não salvas, tem certeza que deseja fechar?" />
      <Form
        form={form}
        layout="vertical"
        requiredMark
        initialValues={item || { name: "Meu cenário", isActive: false, lastNodeId: 0, elements: [] }}
        onValuesChange={(changedValues, values) => {
          setChanged(true)
          if (changedValues.elements) {
            syncElementsOnValuesChangeDebounced(values.elements)
          }
        }}
        onFinish={(model) => {
          save({
            // ...model,
            ...mountWorkflowModel(model),
            metaConfigs: {
              saveOnEnd: true,
              saveWithPatch: true,
            },
          }).then((data: Workflow) => {
            setItemFromVersion(null)
            toast.success("Cenário salvo")
            props.onSubmitSuccess?.(data)
            setChanged(false)
            if (id == "new") {
              history.replace("/settings/workflows/" + data.id)
            }
          })
        }}
      >
        <div
          className={twMerge(
            "absolute w-[calc(100vw-var(--sidebar-w))] h-full left-0 top-0 bg-base-100 z-[1] px-4 pb-3",
            fullscreen && "fixed w-screen h-screen !p-0 z-30 dark:bg-[#18191a] workflow-fullscreen",
            showRunDrawer && styles.running,
            styles.workflow
          )}
        >
          <Spin spinning={spinning} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[2]" />
          <div className="flex flex-col h-full w-full">
            <WorkflowHeader workflow={item!} changed={changed} setChanged={setChanged} fullscreen={fullscreen} />
            <div className="relative w-full h-full overflow-hidden rounded-xl flex-1">
              <div className={twMerge(styles.workflow_grid, "dark:bg-[#18191a]", fullscreen && "!rounded-none")}>
                <div className="dark:opacity-10" />
              </div>
              {!isMobile && <BottomRightPanel undoActions={undoActions} fullscreen={fullscreen} setFullscreen={setFullscreen} />}
              {!isMobile && <BottomMidPanel setElements={setElements} />}
              <WorkflowCanva
                elements={elements}
                setElements={setElements}
                setChanged={setChanged}
                syncElements={syncElements}
                form={form}
                editNode={editNode}
                setEditNodeId={setEditNodeId}
                setUnblocked={setUnblocked}
                unblocked={unblocked}
                goToPosition={goToPosition}
                goToPositionInstant={goToPositionInstant}
                setGoToPosition={setGoToPosition}
                item={item!}
              />
              <BaseEditAction elements={elements} form={form} editNode={editNode} syncElements={syncElements} />
            </div>
          </div>

          <div>
            <Form.Item name="id" noStyle>
              <div />
            </Form.Item>
            <Form.Item name="name" noStyle>
              <div />
            </Form.Item>
            <Form.Item name="active" noStyle>
              <div />
            </Form.Item>
            <Form.Item name="lastNodeId" noStyle>
              <div />
            </Form.Item>
            <Form.Item name="elements" noStyle>
              <div />
            </Form.Item>
          </div>
        </div>
      </Form>
    </ReactFlowProvider>
  )
}

export default EditWorkflow
