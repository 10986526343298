import React, { useMemo, useState } from "react"
import { Button, Drawer, Spin, Timeline } from "antd"
import { throttle } from "lodash"
import { Workflow, WorkflowActionLog, WorkflowLog, WorkflowRunStatus } from "models/workflows"
import { twMerge } from "tailwind-merge"
import { useQuery } from "react-query"
import request from "lib/request"
import { endpoints } from "config/endpoints.config"
import { VariableCategory, WorkflowActionsItem, WorkflowDefs } from "../workflow.defs"
import Tooltip from "components-old/Tooltip"
import { colors } from "lib/colors"
import { Alert01Icon, CancelCircleIcon, CheckmarkCircle02Icon, Clock01Icon, ComputerVideoIcon, Spin05Icon } from "components-old/icons"
import { WorkflowTriggerDefs, WorkflowTriggerItem } from "../actions/workflow.triggers.defs"
import { formatDateBarDayMonthTime } from "lib/helper"
import moment from "moment"
import { format } from "date-fns"
import AnalyticsExecutionActionModal from "./AnalyticsExecutionActionModal"
import AnalyticsActionItem from "./AnalyticsActionItem"
import { useRecoilState } from "recoil"
import { themeAtom } from "atoms/app.atom"
import { ActionEnum } from "../workflow.enums"

interface AnalyticsExecutionModalProps {
  onCancel: () => void
  executionLog: WorkflowLog | null
  workflow?: Workflow
  adminMode?: boolean
}

export enum ErrorEnum {
  UNKNOWN = "unknown",
  TRAIL_LOOP_EXCEEDED = "trail_loop_exceeded",
  ACTION_NOT_FOUND = "action_not_found",
}

export interface ExecutionLogItem {
  id: any
  Icon: any
  label: string
  category: VariableCategory
  timeFromNow: string
  timeComplete: string
  actionId: string
  description: any
  actionRecord?: WorkflowActionsItem
  actionLog?: WorkflowActionLog
  adminMode?: boolean
}

const getErrorMessage = (error) => {
  switch (error.type) {
    case ErrorEnum.ACTION_NOT_FOUND:
      return "Ação não encontrada."
    case ErrorEnum.TRAIL_LOOP_EXCEEDED:
      return "Limite de loop excedido."

    default:
      return error.message
  }
}

function AnalyticsExecutionModal(props: AnalyticsExecutionModalProps) {
  const [theme] = useRecoilState(themeAtom)
  const endpoint = `${endpoints.WORKFLOW}/${props.executionLog?.workflowId}/logs/${props.executionLog?._id}`
  const [executionActionLogItem, setExecutionActionLogItem] = useState<ExecutionLogItem | null>(null)
  // const [search, setSearch] = useState("")

  // const setSearchDebounced = useMemo(
  //   () =>
  //     throttle((value) => {
  //       setSearch(value)
  //     }, 100),
  //   []
  // )

  const { isLoading, error, data, isFetched, isFetching, refetch } = useQuery({
    queryKey: [endpoint],
    enabled: !!props.executionLog?._id,
    queryFn: () => request.get(`${endpoint}${props.adminMode ? "/admin/" + props.executionLog?.workspaceId : ""}`).then((res) => res.data),
  })

  const { items } = useMemo(() => {
    if (!props.executionLog) return { items: [] }

    let items: ExecutionLogItem[] = []
    let triggerRecord: WorkflowTriggerItem | null = null
    let actionsLogs: WorkflowActionLog[] = data?.logActions?.data

    // EXECUTING
    if (!data?.logActions?.data && data?.live) {
      try {
        const trail = [...(data?.trailString?.map((t) => JSON.parse(t)) || [])].reverse()

        actionsLogs = trail?.map((trail) => {
          try {
            // const actionLive = JSON.parse(data?.live?.[trail.actionId] || "{}")
            const action = props.workflow?.actions[trail.actionId]
            const actionResultLive = JSON.parse(data?.live?.["context#" + trail.actionId] || "{}")
            return {
              _id: trail.actionId,
              id: trail.actionId,
              workspaceId: data?.log?.workspaceId,
              workflowId: data?.log?.workflowId,
              executionId: data?.log?.id,
              actionId: trail.actionId,
              actionType: action?.type as ActionEnum,
              startedAt: trail.startedAt,
              endedAt: trail.endedAt,
              context: actionResultLive,
              createdAt: trail.startedAt,
              updatedAt: trail.endedAt || trail.startedAt,
            } as any
          } catch (error) {
            console.log(error)
            return {} as any
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    // const actionLogs: WorkflowActionLog[] = [...data?.logActions?.data].reverse() || []
    // Trigger
    const triggerType = props.executionLog?.trigger?.type
    triggerRecord = WorkflowTriggerDefs.TriggerRecord[triggerType!]
    const TriggerIcon = triggerRecord?.icon
    const isToday = moment(props.executionLog?.startedAt).isSame(new Date(), "day")

    const triggerItem = {
      id: props.executionLog?._id,
      Icon: WorkflowDefs.ActionRecord.trigger.icon,
      label: "Gatilho",
      category: WorkflowDefs.CategoryRecord[triggerRecord.variableCategory!],
      timeFromNow: `há ${moment(props.executionLog?.startedAt).fromNow(true)}`,
      timeComplete: isToday
        ? format(new Date(props.executionLog?.startedAt!), "'Hoje' HH:mm")
        : formatDateBarDayMonthTime(props.executionLog?.startedAt),
      actionId: "trigger",
      // description: triggerRecord.name,
      description: (
        <span className="inline-flex flex-wrap items-center gap-2 rounded-full px-2 py-1 bg-base-300">
          <TriggerIcon className="w-5 h-5 text-green-600" />
          <span>{triggerRecord.name}</span>
        </span>
      ),
      actionRecord: WorkflowDefs.ActionRecord.trigger,
      actionLog: {},
    }

    console.log("actionsLogs", actionsLogs)

    items = [
      ...(props.executionLog?.status === WorkflowRunStatus.RUNNING
        ? [
            {
              id: "executing",
              Icon: Spin05Icon,
              label: "Executando...",
              category: {
                type: "",
                label: "",
                icon: "",
                palette: "slate",
              },
              timeFromNow: "",
              timeComplete: "",
              actionId: "",
              description: "",
              actionRecord: undefined,
              actionLog: undefined,
            },
          ]
        : []),
      ...(props.executionLog?.status === WorkflowRunStatus.AWAITING
        ? [
            {
              id: "executing",
              Icon: Spin05Icon,
              label: "Aguardando delay...",
              category: {
                type: "",
                label: "",
                icon: "",
                palette: "amber",
              },
              timeFromNow: "",
              timeComplete: "",
              actionId: "",
              description: "",
              actionRecord: undefined,
              actionLog: undefined,
            },
          ]
        : []),
      ...(actionsLogs?.map((actionLog) => {

        const actionRecord = WorkflowDefs.ActionRecord[actionLog.actionType]
        const category = WorkflowDefs.CategoryRecord[actionRecord?.variableCategory] || WorkflowDefs.CategoryRecord.utils
        const Icon = actionRecord?.icon || WorkflowDefs.CategoryRecord.utils.icon
        const startedAt = new Date(actionLog?.startedAt)
        const isToday = moment(startedAt).isSame(new Date(), "day")
        let description: any = ""
        try {
          description = actionRecord?.getDescription?.({ category, actionLog, executionLog: props.executionLog! })
        } catch (error) {
          console.log(error)
        }

        if (props.executionLog?.status === WorkflowRunStatus.FAILED && props.executionLog?.error?.onActionId === actionLog.actionId) {
          description = (
            <div className="flex items-center gap-1 text-xs font-medium text-red-500">
              {/* <CancelCircleIcon className="w-4 h-4" /> */}
              {/* <Alert01Icon className="w-4 h-4" /> */}
              <i className="far fa-times-circle text-2xl absolute top-3.5 right-4" />
              <div className="bg-red-100 dark:bg-base-500 rounded-full py-1 px-2">{getErrorMessage(props.executionLog?.error)}</div>
            </div>
          )
        }

        return {
          id: actionLog._id,
          Icon,
          label: actionRecord?.name,
          category,
          timeFromNow: `há ${moment(startedAt).fromNow(true)}`,
          // timeComplete: formatDateBarTime(actionLog?.startedAt),
          timeComplete: isToday ? format(new Date(actionLog?.startedAt), "'Hoje' HH:mm") : formatDateBarDayMonthTime(actionLog?.startedAt),
          actionId: actionLog.actionId,
          description,
          actionRecord,
          actionLog,
        } as any
      }) || []),
      ...(isFetched ? [triggerItem] : []),
    ]

    return { items, triggerRecord }
  }, [
    data?.live,
    data?.log?.id,
    data?.log?.workflowId,
    data?.log?.workspaceId,
    data?.logActions?.data,
    data?.trailString,
    isFetched,
    props.executionLog,
    props.workflow?.actions,
  ])

  const statusInfo = useMemo(() => {
    let statusInfo
    switch (props.executionLog?.status) {
      case WorkflowRunStatus.FAILED:
        statusInfo = {
          palette: "red",
          label: "Houve falha durante a execução.",
          icon: <Alert01Icon className="text-white" />,
        }
        break
      case WorkflowRunStatus.DONE:
        statusInfo = {
          palette: "teal",
          label: "Execução concluída com sucesso.",
          icon: <CheckmarkCircle02Icon className="text-white" />,
        }
        break
      case WorkflowRunStatus.PENDING:
        statusInfo = {
          palette: "orange",
          label: "Pendente",
          icon: null,
        }
        break
      case WorkflowRunStatus.AWAITING:
        statusInfo = {
          palette: "amber",
          label: "Aguardando",
          icon: null,
        }
        break
      case WorkflowRunStatus.RUNNING:
        statusInfo = {
          palette: "amber",
          label: "Executando",
          icon: (
            <Spin05Icon
              style={{
                color: colors.amber[500],
              }}
              className="w-[14px] h-[14px]"
            />
          ),
        }
        break

      default:
        break
    }
    return statusInfo
  }, [props.executionLog?.status])

  console.log(items)

  return (
    <Drawer
      title={
        <div className="flex items-center justify-between w-full pr-5">
          <div className="flex-1 text-lg">
            Execução
            <span className="text-content-300 !text-3xs ml-1">#{props.executionLog?._id}</span>
          </div>
          {/* <Tooltip title="Monitorar">
            <Button icon={<ComputerVideoIcon />} className="rounded-xl" disabled>
              Monitorar
            </Button>
          </Tooltip> */}
        </div>
      }
      headerStyle={{
        paddingRight: 0,
      }}
      className="g_trigger_modal no-mask"
      visible={!!props.executionLog}
      footer={null}
      // closable={false}
      closeIcon={<i className={twMerge("far fa-times")} />}
      width={400}
      bodyStyle={{
        minHeight: 400,
      }}
      maskClosable
      maskStyle={{
        transition: "none",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      // onCancel={props.onCancel}
      onClose={props.onCancel}
      zIndex={31}
    >
      {!!props.executionLog && (
        <div>
          {props.executionLog && props.executionLog.status !== "running" && props.executionLog.status !== "awaiting" && (
            <div
              className="p-3 mb-6 rounded-xl"
              style={{
                backgroundColor: colors[statusInfo.palette][theme === "dark" ? 700 : 500],
                // borderColor: colors[statusInfo.palette][500]
              }}
            >
              <div className="flex items-center gap-2.5">
                {statusInfo.icon}
                <div className="leading-[18px] font-normal">
                  <div
                    className="font-bold text-[13px] truncate text-white"
                    // style={{
                    //   color: colors[statusInfo.palette][0],
                    // }}
                  >
                    {statusInfo.label}
                  </div>
                  {props.executionLog.status === "failed" && (
                    <div className="text-2xs font-normal text-white">{getErrorMessage(props.executionLog?.error)}</div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* <Timeline prefixCls="timeline-messages timeline-activities" className="mt-2 pt-3 px-3" mode="right"> */}
          <Spin spinning={isLoading} className={twMerge(isLoading && "mt-8")}>
            {items?.map((item, index) => {
              return <AnalyticsActionItem key={item.id} item={item} index={index} setExecutionActionLogItem={setExecutionActionLogItem} />
            })}
          </Spin>
          {/* </Timeline> */}
        </div>
      )}
      <AnalyticsExecutionActionModal
        executionLog={props.executionLog}
        executionActionLogItem={executionActionLogItem}
        onCancel={() => {
          setExecutionActionLogItem(null)
        }}
      />
    </Drawer>
  )
}

export default AnalyticsExecutionModal
