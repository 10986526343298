import { Form } from "antd"
import ChannelsSelect from "components-old/ui/ChannelsSelect"
import { CHANNEL_TYPES, WhatsappVersion } from "lib/constants"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import SelectReference from "../../../actions/SelectReference"
import ConversationFormCore from "../ConversationFormCore"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import NewMessageTemplate, { ITemplateMessage } from "components-old/inbox/NewMessageModal/NewMessageTemplate"
import { useStoreState } from "hooks"
import { useState } from "react"

interface CreateConversationActionProps extends BaseEditActionChildrenProps {}

const CreateConversationAction = (props: CreateConversationActionProps) => {
  const fieldName = [...props.actionFieldName, "conversation"]
  const { all: channels } = useStoreState((store) => store.channels)
  const [templates, setTemplates] = useState<ITemplateMessage[]>([])

  return (
    <div>
      <Form.Item
        label="Contato"
        name={[...fieldName, "contactReference"]}
        rules={[
          {
            required: true,
            message: "Obrigatório",
          },
        ]}
      >
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.contact} />
      </Form.Item>
      <ChannelsSelect
        label="Canal"
        placeholder="Selecione um canal"
        required
        name={[...fieldName, "channelId"]}
        filter={(c) => c.channelId == CHANNEL_TYPES.WHATSAPP}
      />
      {/* <Form.Item shouldUpdate noStyle>
        {(formInstance) => {
          const channel = channels.find((c) => c.id == formInstance.getFieldValue([...fieldName, "channelId"]))!

          if (!channel || channel?.version !== WhatsappVersion?.API_CLOUD) return null

          return (
            <NewMessageTemplate
              channel={channel}
              templates={templates}
              setTemplates={setTemplates}
              text={formInstance.getFieldValue([...fieldName, "text"])}
              setText={(text) =>
                formInstance.setFields([
                  {
                    name: [...fieldName, "text"],
                    value: text,
                  },
                ])
              }
              files={[]}
              setFiles={() => {}}
              parentPath={[...fieldName]}
              contentClassname="bordered mb-4"
            />
          )
        }}
      </Form.Item> */}
      <Form.Item name={[...fieldName, "text"]} noStyle>
        <div />
      </Form.Item>
      <ConversationFormCore fieldName={fieldName} />
    </div>
  )
}

export default CreateConversationAction
