import { Input, InputNumber, Select, Switch } from "antd"
import { InputType } from "lib/constants"
import { formatterCurrencyNewWithoutDollarSign, parseDecimal } from "lib/masks"
import { InputField } from "pages/settings/workflows/workflow.defs"
import React from "react"
import CustomSelect from "../CustomSelect"
import AgentsSelect from "components-old/ui/AgentsSelect"
import GroupsSelect from "components-old/ui/GroupsSelect"
import TagsSelect from "components-old/ui/TagsSelect"
import PipelinesSelect from "components-old/ui/PipelinesSelect"
import DateInput from "components/form/DateInput"

interface CustomFieldInputProps {
  field?: InputField
  htmlInputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  value?: any
  onChange?: (value: any) => void
}

function CustomFieldInput(props: CustomFieldInputProps) {
  switch (props.field?.type) {
    // case InputType.CustomNode:
    //   return props.field?.getNode(props)
    case InputType.Date:
      return <DateInput value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.Number:
      return <InputNumber style={{ width: "100%" }} value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.Decimal:
      return (
        <InputNumber
          className="w-full"
          parser={parseDecimal as any}
          formatter={formatterCurrencyNewWithoutDollarSign}
          step={0.01}
          min={0}
          value={props.value}
          onChange={props.onChange}
          {...(props.htmlInputProps as any)}
        />
      )
    case InputType.Boolean:
      return (
        // <Checkbox></Checkbox>
        <Switch
          checked={props.value === "1"}
          onChange={(v) => {
            return props.onChange?.(v ? "1" : "0")
          }}
          {...(props.htmlInputProps as any)}
        />
        // <Select /
      )
    case InputType.Textarea:
      return <Input.TextArea rows={6} value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.Select:
      return (
        <CustomSelect allowClear showSearch value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)}>
          {props.field?.options?.map((option) => (
            <Select.Option key={option.value} value={option.value} data-search={(option.label || "") + option.value}>
              {option?.label || option.value}
            </Select.Option>
          ))}
        </CustomSelect>
      )
    case InputType.Assignee:
      return <AgentsSelect unassigned value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.Group:
      return <GroupsSelect value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.Tag:
      return <TagsSelect value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.Pipeline:
      return <PipelinesSelect unassigned value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.MultiSelect:
      return (
        <CustomSelect mode="multiple" showSearch value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)}>
          {props.field?.options?.map((option) => (
            <Select.Option key={option.value} value={option.value} data-search={(option.label || "") + option.value}>
              {option.label || option.value}
            </Select.Option>
          ))}
        </CustomSelect>
      )
    // case InputType.JSON:
    //   if (props?.json) {
    //     let defaultValue
    //     try {
    //       defaultValue = JSON.stringify(JSON.parse(props.json?.value), null, "\t")
    //     } catch (e) {
    //       defaultValue = props.json?.value || ""
    //     }

    //     return (
    //       <div>
    //         <div
    //           style={{
    //             border: "1px solid rgba(0, 0, 0, 0.15)",
    //             borderRadius: 4,
    //           }}
    //         >
    //           <AceEditor
    //             mode="json"
    //             theme={props.json?.theme == "dark" ? "terminal" : "github"}
    //             onChange={(value) => {
    //               if (props.json?.onChange) {
    //                 props?.json.onChange(value)
    //               } else {
    //                 props.json?.form?.setFields([
    //                   {
    //                     name: ["customFields", props.json?.name],
    //                     value: { value },
    //                   },
    //                 ])
    //               }
    //             }}
    //             name={"ACE_EDITOR" + props.json.name}
    //             defaultValue={defaultValue}
    //             width="470px"
    //             style={props.json.style}
    //           />
    //         </div>
    //       </div>
    //     )
    //   }
    case InputType.Text:
      return <Input value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
    case InputType.CustomNode:
      // const clonedChild = React.cloneElement((props.field?.custom as any)!, {
      //   value: props.value,
      //   onChange: props.onChange,
      //   ...(props.htmlInputProps as any),
      // })
      return props.field?.custom as React.ReactElement
    default:
      return <Input value={props.value} onChange={props.onChange} {...(props.htmlInputProps as any)} />
  }
}

export default CustomFieldInput
