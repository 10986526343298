import { Col, Form, InputNumber, Row } from "antd"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import CustomSelect from "components-old/forms/CustomSelect"
import { useEffect } from "react"
import { AddListButton } from "components-old/shared/table/DnDFormList/AddListButton"
import BaseUpdateActionItem from "pages/settings/workflows/actions/BaseUpdateActionItem"
import InputVariable from "components-old/forms/InputVariable"
import InputVariableFormItem from "components-old/forms/InputVariable/InputVariableFormItem"
import { LabelHelp } from "components-old/forms/LabelHelp"
import { Operators, OperatorsLabel } from "lib/constants"

interface DecisionActionProps extends BaseEditActionChildrenProps {}

const DecisionAction = (props: DecisionActionProps) => {
  const fieldName = [...props.actionFieldName, "decision"]

  useEffect(() => {
    if (!props.form.getFieldValue(fieldName)?.unit) {
      setTimeout(() => {
        props.form.setFields([{ name: fieldName, value: { duration: 1, unit: "minutes" } }])
        props.syncElements()
      }, 100)
    }
  }, [])

  return (
    <div className="">
      <Form.Item label={<LabelHelp help={""}>Campo</LabelHelp>} name={[...fieldName, "field"]}>
        <InputVariable />
      </Form.Item>
      <Form.Item label={<LabelHelp help={""}>Operador</LabelHelp>} name={[...fieldName, "operator"]}>
        <CustomSelect
          className="w-full flex-1"
          options={[
            Operators.Is,
            Operators.IsNot,
            Operators.GreaterThan,
            Operators.LessThan,
            Operators.Contains,
            Operators.NotContains,
            Operators.Empty,
            Operators.NotEmpty,
            Operators.StartsWith,
            Operators.NotStartsWith,
          ].map((operator) => ({ label: OperatorsLabel[operator], value: operator }))}
        />
      </Form.Item>
      <Form.Item label={<LabelHelp help={""}>Valor</LabelHelp>} name={[...fieldName, "value"]}>
        <InputVariable />
      </Form.Item>

      {/* <Form.Item label="Duração" name={[...fieldName, "duration"]} required>
          <InputNumber className="w-full flex-1" />
        </Form.Item>
        <Form.Item label="Unidade" name={[...fieldName, "unit"]} required className="w-full flex-1">
          <CustomSelect
            className="w-full flex-1"
            options={[
              { label: "Segundos", value: "seconds" },
              { label: "Minutos", value: "minutes" },
              { label: "Horas", value: "hours" },
              { label: "Dias", value: "days" },
            ]}
          />
        </Form.Item> */}
    </div>
  )
}

export default DecisionAction
