import React from "react"
import Lottie from "react-lottie"
import successLottie from "assets/animations/success-blue.json"
import { Button } from "antd"
import { useHistory } from "react-router-dom"

interface AuthSuccessProps {
  description?: string | React.ReactNode
  animationData?: any
  children?: React.ReactNode
  success?: boolean
  successBtn?: React.ReactNode
}

function AuthSuccess(props: AuthSuccessProps) {
  const history = useHistory()

  if (!props.success) {
    return <>{props.children}</>
  }

  return (
    <>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: props.animationData || successLottie,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={120}
        width={140}
        speed={1}
      />
      <br />
      <p className="text-center max-w-xs">{props.description}</p>
      <br />
      {props.successBtn || (
        <Button
          size="large"
          onClick={() => {
            history.push("/auth/login")
          }}
        >
          Voltar ao início
        </Button>
      )}
    </>
  )
}

export default AuthSuccess
