import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, PrivateGenericResource, generic } from "models/generic"
import { WorkflowTriggerTypeEnum } from "pages/settings/workflows/actions/workflow.triggers.defs"
import { WorkflowNodeAction, WorkflowNodeData } from "pages/settings/workflows/workflow.defs"
import { ActionEnum } from "pages/settings/workflows/workflow.enums"
import { Elements } from "react-flow-renderer"

// export type IBotActionType =
//   | "start"
//   | "message"
//   | "attachment"
//   | "data_input"
//   | "set_field"
//   | "add_tags"
//   | "delay"
//   | "assign"
//   | "notify"
//   | "integration"
//   | "menu"
//   | "decision"
//   | "end"
//   | "keyword"
//   | "llm"
//   | "deal"

export enum WorkflowRunStatus {
  RUNNING = "running",
  DONE = "done",
  FAILED = "failed",
  PENDING = "pending",
  AWAITING = "awaiting",
}

interface WorkflowTrail {
  startedAt: string
  endedAt: string
  actionId: string
}

interface WorkflowReportDay {
  date: string
  done: number
  failed: number
  operations: number
}

export interface WorkflowAction {
  id: string
  type: ActionEnum
  targets?: Record<string, string>
  source?: string
  data?: WorkflowNodeAction
  // targets?: {
  //   sourceHandle: string
  //   target: string
  // }[]
}

export interface Workflow extends PrivateGenericResource {
  name: string
  active: boolean
  elements: Elements<WorkflowNodeData>
  lastNodeId: number
  actions: Record<string, WorkflowAction>
  reportDays: WorkflowReportDay[]
}

export interface WorkflowLog extends GenericResource {
  workflowId: string
  operationsConsumed: number
  currentActionId: string
  pending: boolean
  // trail: Record<string, any>
  trail?: WorkflowTrail[]
  nodes?: Record<
    string,
    {
      type: ActionEnum
      runCounts: number
    }
  >
  startedAt: string
  endedAt: string
  duration: number
  status: WorkflowRunStatus
  trigger: {
    data: any
    type: WorkflowTriggerTypeEnum
  }
  error?: {
    type: string
    message: string
    onActionId: string
    failedAt: Date
    details?: {
      message: string
      stack: string
    }
  }
}

export interface WorkflowActionLog {
  _id: string
  workflowId: string
  executionId: string
  actionId: string
  actionType: ActionEnum
  startedAt: string
  endedAt: string
  context?: any
}

export const workflows: IGenericModel<Workflow> = {
  ...generic(endpoints.WORKFLOW),
}

export const workflowLogs: IGenericModel<WorkflowLog> = {
  ...generic(endpoints.WORKFLOW_LOGS),
}
