import { DatePicker, DatePickerProps } from "antd"
import moment from "moment"
import { Moment } from "moment"
import React, { useEffect, useState } from "react"

type DateInputProps = {
  value?: Date
  onChange?: (value: Date) => void
} & DatePickerProps

function DateInput(props: DateInputProps) {
  const [value, setValue] = useState<Moment | null>()

  useEffect(() => {
    try {
      const momentValue = props.value ? moment(props.value) : null
      setValue(momentValue)
    } catch (e) {
      setValue(null)
    }
  }, [props.value])

  return <DatePicker {...props} value={value} onChange={props.onChange} />
}

export default DateInput
