import { GenericResource } from "models/generic"

export enum NotificationType {
  COMMENT = "comment",
  MENTION = "mention",
  REACTION = "reaction",
  ASSIGN = "assign",
  EVENT = "event",
}

export enum NotificationSubType {
  ACTIVITY = "activity",
  DEAL = "deal",
  CONVERSATION = "conversation",
  TASK = "task",
  WORKSPACE_CREATED = "workspace_created",
  DASHBOARD_SNAPSHOT = "dashboard_snapshot",
}

export interface Notification extends GenericResource {
  authorAvatar?: string
  authorName?: string
  authorId?: number
  operatorId: number
  unread?: boolean
  link?: string
  description?: string
  contextName?: string
  type?: NotificationType
  subtype?: NotificationSubType
  otherContext?: {
    platformId: string
    conversation?: {
      id?: string
      name?: string
      avatar?: string
      workspacePlatformId?: string
      text?: string
    }
    deal?: {
      id?: string
      name?: string
      text?: string
      pipelineId?: string
    }
    task: {
      name: string
      description: string
      id: string
      from: string
    }
    dashboard?: {
      id: string
      processedAt?: string
      status?: string
      dashboardName?: string
    }
  }
}
