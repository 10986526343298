import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function runWorker<TInput, TContext, TResult>(workerFactory: () => Worker, data: TInput, context?: TContext): Promise<TResult> {
  return new Promise((resolve, reject) => {
    const worker = workerFactory() // Inicializa o Worker a partir da fábrica fornecida

    // Escuta a resposta do Worker
    worker.onmessage = (event) => {
      resolve(event.data as TResult) // Resolve a Promise com o resultado do Worker
      worker.terminate() // Finaliza o Worker
    }

    // Escuta erros no Worker
    worker.onerror = (error) => {
      reject(error) // Rejeita a Promise em caso de erro
      worker.terminate() // Finaliza o Worker
    }

    // Envia os dados para o Worker
    worker.postMessage({
      data,
      context: Object.entries(context || {}).reduce((acc, [key, value]) => ({ ...acc, [key]: value?.toString() }), {}),
    })
  })
}
