import { useEffect, useMemo, useState } from "react"
import { Button, Spin } from "antd"
import DefaultForm from "components/form/DefaultForm"
import { useStore } from "hooks"
import { general } from "store"
import cx from "classnames"
import Page from "components/utilities/Page"
import Avatar from "components-old/ui/Avatar"
import AuthLogo from "components/utilities/AuthLogo"
import Separator from "components/navigation/Separator"
import Modal from "components-old/Modal"
import AwaitingPaymentModal from "pages/settings/billing/AwaitingPaymentModal"
import AuthSuccess from "../AuthSuccess"
import { endpoints } from "config/endpoints.config"
import request from "lib/request"
import toast from "lib/toast"
import { useParams } from "react-router-dom"
import AppLoader from "components-old/ui/AppLoader"
import { Helmet } from "react-helmet"

const CheckoutItem = ({ icon, title, value }: { icon: string; title: string; value: number }) => {
  return (
    <div className="rounded-xl flex items-center  gap-4">
      <i className={cx(icon, "text-2xl bg-base-200 p-2 w-12 h-12 fc rounded-full")} />
      <div className="flex items-center gap-4 text-left">
        <span className="text-2xl font-bold">{value}</span>
        <span className="text-sm text-gray-500">{title}</span>
      </div>
    </div>
  )
}

interface IPlan {
  operators: number
  whatsapp: number
  instagram: number
  total: number
  discount_percentage: number
  months: number
  paymentMethods: string[]
  workspaceName: string
  workspaceToken: string
  workspaceAvatar: string
  workspaceId: string
  partnerName: string
  cupomCode: string
}

function PartnerCheckout() {
  const [plan, setPlan] = useState<IPlan | undefined>()
  const [loading, setLoading] = useState(false)
  const { _isProduction } = useStore(general)
  const [awaitingPayment, setAwaitingPayment] = useState(false)
  const [success, setSuccess] = useState(false)
  const params = useParams<any>()

  const onSubmit = () => {
    if (!(window as any)?.PagarMeCheckout) {
      return
    }
    if (!plan) {
      toast.error("Código inválido")
      setTimeout(() => {
        window.location.href = "https://go.kinbox.com.br"
      }, 2000)
      return
    }
    setLoading(true)

    var checkout = new (window as any).PagarMeCheckout.Checkout({
      encryption_key: _isProduction ? "ek_live_TsTh6VnuQBZBAYIpX2WeHLs3HKizSJ" : "ek_test_FMou8K1VTMdatfs0M35NzTbGxzhcdB",
      success: async function (data) {
        setLoading(false)
        setAwaitingPayment(true)
        const response = await request.post(
          endpoints.PAYMENT,
          {
            ...data,
            agents: plan.operators,
            numbers: plan.whatsapp,
            instagramUnits: plan.instagram,
            months: plan.months,
            code: plan.cupomCode,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${plan.workspaceToken}`,
            },
          },
        )
        const errors = response.data.error || response.data.errors || (response as any).error || (response as any).errors

        if (errors) {
          toast.error(errors.message)
          setAwaitingPayment(false)
        } else {
          setSuccess(true)
          await request.delete(
            endpoints.CHECKOUT_LINK + "/" + params.code,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${plan.workspaceToken}`,
              },
            },
          )
        }
      },
      error: function (err) {
        toast.error("Erro ao processar o pagamento")
        setLoading(false)
        setAwaitingPayment(false)
        console.log(err)
      },
      close: async function () {
        // toast.error("Pagamento cancelado")
        setLoading(false)
      },
    })

    // Obs.: é necessário passar os valores boolean como string
    checkout.open({
      amount: Math.round(plan.total * 100),
      buttonText: "Pagar",
      buttonClass: "botao-pagamento",
      customerData: "true",
      createToken: "false",
      paymentMethods: plan.paymentMethods?.join(","),
    })
  }

  useEffect(() => {
    const run = async () => {
      const response = await request
        .get<IPlan>(endpoints.CHECKOUT_LINK + "/" + params.code)
        .then((response) => {
          if (response.data.workspaceId) {
            setPlan(response.data)
          } else {
            toast.error("Código inválido")
            setTimeout(() => {
              window.location.href = "https://go.kinbox.com.br"
            }, 3000)
          }
        })
        .catch((err) => {
          toast.error("Código inválido")
          setTimeout(() => {
            window.location.href = "https://go.kinbox.com.br"
          }, 2000)
        })
    }
    run()
  }, [])

  if (!plan) {
    return (
      <Page title="Checkout">
        <AppLoader />
      </Page>
    )
  }

  return (
    <Page title="Checkout">
      <Helmet>
        <script src="https://assets.pagar.me/checkout/1.1.0/checkout.js"></script>
      </Helmet>
      <div className="bg-base-200 w-screen h-screen fc flex-col gap-8">
        <div
          className="max-w-2xl w-full p-10 bg-base-100 rounded-2xl"
          style={{
            boxShadow: "0 2px 4px rgba(40, 41, 61, .04), 0 8px 16px rgba(96, 97, 112, .16)",
          }}
        >
          <AuthLogo />

          {awaitingPayment ? (
            <>
              <div className="text-2xl text-center fc flex-col">
                <AuthSuccess
                  success={success}
                  description="Seu pagamento foi confirmado!"
                  successBtn={
                    <Button
                      size="large"
                      loading={loading}
                      onClick={() => {
                        window.location.href = "https://go.kinbox.com.br"
                      }}
                      className="w-full h-14 text-lg !font-bold"
                    >
                      Ir para o login
                    </Button>
                  }
                >
                  <Spin spinning />
                  <br />
                  <h3>Confirmando seu pagamento...</h3>
                </AuthSuccess>
              </div>
            </>
          ) : (
            <>
              <h1 className="text-5xl font-bold w-full mb-6">Checkout</h1>

              <div
                className="flex bg-base-200 gap-4 items-center p-4 rounded-2xl my-8"
                // style={{
                //   boxShadow: "rgba(8, 35, 48, 0.1) 0px 4px 8px 0px",
                // }}
              >
                <Avatar size={100} imgSrc={plan.workspaceAvatar} />
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-500">Ambiente</span>
                  <span className="text-xl font-bold">{plan.workspaceName}</span>
                </div>
              </div>
              <DefaultForm className="w-full" onFinish={onSubmit}>
                <div className="flex flex-col gap-4">
                  <CheckoutItem icon="fal fa-user" title="Agentes" value={plan.operators} />
                  <CheckoutItem icon="fab fa-whatsapp" title="Canais de Whatsapp" value={plan.whatsapp} />
                  <CheckoutItem icon="fab fa-instagram" title="Canais de Instagram" value={plan.instagram} />
                  <Separator />
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <span className="text-md text-gray-500">Total mensal:</span>
                      <span className="text-2xl font-bold">R$ {plan.total}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-md text-gray-500">Desconto mensal:</span>
                      <span className="text-md font-bold text-green-600">{plan.discount_percentage}%</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 text-center mt-10">
                  <Button type="primary" size="large" loading={loading} htmlType="submit" className="w-full h-14 text-lg !font-bold">
                    Pagar
                  </Button>
                </div>
              </DefaultForm>
            </>
          )}
        </div>
        <div className="text-center text-sm text-gray-400">
          Gerado pelo parceiro <strong>{plan.partnerName}</strong>
        </div>
      </div>
    </Page>
  )
}

export default PartnerCheckout
