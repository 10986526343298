import React, { useMemo } from "react"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { useRecoilState } from "recoil"
import { variableAtom } from "atoms/app.atom"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { OperatorsLabel } from "lib/constants"
import Tooltip from "components-old/Tooltip"
import { Handle, Position } from "react-flow-renderer"
import styles from "../../../actions/style.module.css"
import cx from "classnames"

interface DecisionNodeProps extends BaseNodeProps {}

function DecisionNode(props: DecisionNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { fields } = useMemo(() => {
    let fields: {
      // icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = [
      {
        value: transformStringToJSX(props.data?.action?.decision?.field ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "Campo",
      },
      {
        value: OperatorsLabel[props.data?.action?.decision?.operator ?? ""],
        label: "Operador",
      },
      {
        value: (
          <span className="text-primary font-bold">
            {transformStringToJSX(props.data?.action?.decision?.value ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord })}
          </span>
        ),
        label: "Valor",
      },
    ]

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.decision])

  return (
    <BaseNode
      {...props}
      title="Decisão"
      defaultHandleLabel="Sim"
      handlesAfter={
        <div className="flex justify-end items-center h-6 mb-2 -mt-4">
          <span className="text-3xs -mr-2 text-content-200 whitespace-nowrap bg-red-50 dark:bg-[rgba(0,0,0,0.1)] text-red-600 px-2 rounded-full">
            Não
          </span>
          <Handle type="source" position={Position.Right} id="not_found" className={styles.node_handle_source_wrapper}>
            <div className={cx(styles.node_handle, "bg-red-500 shrink-0")} />
          </Handle>
        </div>
      }
    >
      <div>
        {fields?.map((field, index) => (
          <span key={index} className="mx-0.5">
            {field.value}
          </span>
        ))}
      </div>
      {/* <BaseExpanderFields fields={fields} category={CategoryEnum.UTILS} color={WorkflowDefs.ActionRecord.decision.colors?.bg} /> */}
    </BaseNode>
  )
}

export default DecisionNode
