let domain = "http://localhost:3001"

export const endpoints = {
  MASTER: "/master",
  LOGIN_ADMIN_INSPECT: "/admin/users/login-user",
  LOGIN_PARTNER_INSPECT: "/partner/users/login-user",
  WORKSPACE: "/workspace",
  WORKSPACE_OPERATOR: "/workspace/operator",
  UPLOAD_FILE: "/v1/assets/upload",
  REFRESH_PAGE: "/v1/debug/refresh-page",
  HELP_SUBSCRIBE: "/master/help",
  AGENT_MESSAGES: "/chat/team-messages",
  ROOMS: "/rooms",

  // Operators
  OPERATOR: "/operators",
  LOGIN: "/operators/login",
  RENEW_TOKEN: "/operators/renew-token",
  CHANGE_PASSWORD: "/operators/change-password",
  UPDATE_PROFILE: "/operators/profile",
  SHARED_MEMBERS: "/operators/share-conversations",
  COMPLETE_REGISTER: "/operators/complete-register",
  FORGOT: "/operators/forgot-password",
  RESET: "/operators/reset-password",
  JOIN_WORKSPACE: "/operators/join-workspace",
  RESEND_AGENT_EMAIL: "/operators/resend-email",
  CONFIRM_AGENT_EMAIL: "/operators/confirm-email",
  SAVE_PUSH_SUBSCRIPTION: "/operators/web-push",
  LOGOUT: "/operators/logout",
  RESEND_INVITE: "/operators/resend-invite",
  // Inbox
  INBOX: "/inbox",
  MESSAGE: "/inbox/messages",
  MESSAGE_FORWARD: "/inbox/messages/forward",
  QUICK_PHRASES: "/inbox/quick-phrases",
  SAVE_TAG: "/inbox/tag",
  CUSTOMER_MEDIAS: "/inbox/media-messages",
  TRANSFER_SINGLE_CONVERSATION_CHANNEL: "/inbox/transferSingleCustomerToChannel",
  INBOX_SEGMENTS: "/inbox/segments",
  INBOX_SEGMENTS_COUNT: "/inbox/segments-count",
  FAVORITES: "/inbox/favorites",
  UNREAD: "/inbox/unread",
  PENDING: "/inbox/pending",
  ASSIGNMENT_RULES: "/inbox/assignment",
  SEND_ACTIVE_MESSAGE: "/inbox/active-chat",
  SCHEDULE_MESSAGE: "/inbox/schedule-message",
  PINNED_NOTES: "/inbox/pinned-notes",
  TRANSFER_CHANNEL: "/inbox/transferCustomerPlatform",
  BLOCK_USER: "/inbox/block-user",
  UNBLOCK_USER: "/inbox/unblock-user",
  PRESENCE: "/inbox/contact-presence",
  CONVERT_AUDIO_FALLBACK: "/v1/assist/convert-audio-message-fallback",

  // Calls
  CALLS: "/calls",
  TRANSFER_CALL_AGENT: "/calls/transfer-operator",
  TRANSFER_CALL_GROUP: "/calls/transfer-group",
  INIT_BOT: "/calls/init-bot",

  // Campaigns
  MESSAGE_SCHEDULES: "/campaign/message-schedules",
  MESSAGES_API: "/campaign/messages-automation",
  CAMPAIGN: "/campaign",
  CAMPAIGN_V2: "/v3/campaigns",
  GOALS: "/v3/goals",
  SYSTEM_METRICS_REPORT: "/v3/system-metrics",

  // Contact
  CUSTOMER_MERGE: "/customer/merge-customer",
  CONTACTS: "/contacts",
  CONTACTS_V3: "/v3/contacts",
  CONTACTS_IMPORT: "/contacts/import",

  // Bot
  BOT: "/bot",
  BOT_TEMPLATES: "/bot/templates",
  BOT_RATING: "/bot/rating",

  // Admin
  AUDIT_LOGS_ADMIN: "/v3/audit/admin",
  WALLET_TRANSACTIONS_ADMIN: "/v1/wallet/transactions/admin",
  ADMIN_COUPONS: "/admin/coupons",
  ADMIN_WORKSPACES: "/admin/workspaces",
  WORKSPACES_DAILY: "/v3/workspace-daily",
  ADMIN_WORKSPACES_RESET_CACHE: "/reset-cache",
  ADMIN_LOGS: "/admin/logs",
  ADMIN_LOGS_TYPES: "/admin/logs-types",
  ADMIN_USERS: "/admin/users",
  ADMIN_APPROVALS: "/admin/approvals",
  ADMIN_TRANSACTIONS: "/admin/transactions",
  ADMIN_PAYMENT_LINK: "/admin/payment-link",
  ADMIN_TRANSACTIONS_APPROVE: "/admin/transactions/approve",
  ADMIN_SYSTEM_INFO: "/admin/system-info",
  ADMIN_COMMISSIONS: "/v1/admin/commissions",
  ADMIN_TRANSACTIONS_DETAILS: "/v1/admin/invoices",
  ADMIN_DESACTIVATE_OLD_WORKSPACES: "/admin/workspaces/deactivate-old-workspaces",
  CALCULATE_COMMISSIONS: "/admin/calculate-commissions",
  RESET_PASSWORD: "/admin/users/reset-password",

  // Settings
  AUTOMATION: "/automations",
  AUDIT_LOGS: "/audit",
  GROUP: "/group",
  LABEL: "/v3/tags",
  WEBHOOK: "/webhook",
  BUSINESS_HOURS: "/business-hours",
  PLUGIN: "/plugin",
  KNOWLEDGE_BASE: "/v1/knowledge-bases",
  API_TOKENS: "/workspace/secrets",
  ROLES: "/roles",
  CUSTOM_FIELDS: "/custom-fields",

  // Integrations
  INTEGRATIONS: "/integrations",
  INTEGRATIONS_REGISTER_CLOUD_API: "/integrations/whatsapp-wba-cloud/register",
  CHANNEL: "/integrations/channel",
  TELEGRAM: "/integrations/telegram",
  INSTAGRAM: "/integrations/instagram/v2",
  EMAIL: "/integrations/mail",
  SMS: "/integrations/sms",
  CUSTOM: "/integrations/custom",
  MERCADO_LIVRE: "/integrations/ml",
  MERCADO_LIVRE_DETAILS: "/integrations/ml-api/product-info",

  // Facebook
  FACEBOOK: "/integrations/facebook",
  FACEBOOK_GRAPH_API: "https://graph.facebook.com/v19.0/me/accounts?limit=99&fields=id,name,picture,url,access_token&access_token=",
  INSTAGRAM_GRAPH_API:
    "https://graph.facebook.com/v19.0/me/accounts?limit=99&fields=id,instagram_business_account,picture,url,access_token,name&access_token=",

  // Whatsapp
  WHATSAPP: "/integrations/whatsapp",
  WHATSAPP_QRCODE: "/integrations/whatsapp/qrcode",
  WHATSAPP_LOGOUT: "/integrations/whatsapp/logout",
  WHATSAPP_SYNC: "/integrations/sync-history",
  WHATSAPP_GROUPS: "/integrations/whatsapp/groups",
  WHATSAPP_WBA_CLOUD: "/integrations/whatsapp-wba-cloud",
  RESTART_CONTAINER: "/integrations/restart",
  RECREATE_CHANNEL: "/integrations/whatsapp/recreate",

  // SMS
  SMS_TRANSACTIONS: "/transactions/sms",
  BUY_SMS: "/transactions/sms/buy",

  // WALLET
  WALLET_BUY: "/v1/wallet/buy",
  WALLET_TRANSACTIONS: "/v1/wallet/transactions",

  // Payment
  PAYMENT: "/payment/subscribe",
  CHECKOUT_LINK: "/v3/workspaces/checkout-link",
  PAYMENT_TRANSACTIONS: "/payment/transactions",
  VERIFY_COUPON: "/payment/cupom/verify",

  // Reports
  REPORT_SESSIONS: "/v3/reports/sessions",
  FIX_SESSION: "/v3/reports/fix-session",
  UPDATE_SESSION_BY_CALL_ID: "/v3/reports/sessions/by-call-id",
  REPORT_OVERVIEW: "/v3/reports/overview",
  REPORT_PRODUCTIVITY: "/v3/reports/productivity",
  REPORT_RATING: "/v3/reports/rating",
  REPORT_ASSIGNMENT: "/v3/reports/assignment",
  REPORT_INTERACTIONS: "/v3/reports/interactions",
  REPORT_PRESENCE: "/v3/reports/presence",
  REPORT_PRESENCE2: "/v3/reports/presence/block",
  REPORT_PRESENCE_ADMIN: "/v3/reports/presence/block/admin",
  REPORT_PRESENCE_DASHBOARD: "/v3/reports/presence/dashboard",
  REPORT_PRESENCE_DASHBOARD2: "/v3/reports/presence/block/dashboard",
  VIEW: "/v3/views",
  DASHBOARDS: "/v3/dashboards",
  DOWNLOAD_REPORT_CAMPAIGN: "/report/campaign",
  CONTACT_BY_CONVERSAS: "/v1/reports/conversations",
  REPORT_CONTACT: "/v1/reports/contacts",

  // Partner
  PARTNER: "/partner",
  PARTNER_CUSTOMERS: "/partner/customers",
  PARTNER_WORKSPACES: "/partner/workspaces",
  PARTNER_PAYMENT: "/partner/payments",
  PARTNER_WITHDRAWALS: "/partner/withdrawals",
  PARTNER_GET_PLAN_TOTAL: "/partner/get-plan-total",
  PARTNER_GET_EXTENDED_TRIAL: "/partner/get-extended-trial-price",
  PARTNER_PROMOTE_TO_PRO: "/partner/promote-to-pro",
  PARTNER_CHANGE_PLAN: "/partner/change-plan",
  PARTNER_ADD_CHANNEL_TRIAL: "/partner/add-channel-trial",
  PARTNER_EXTEND_TRIAL: "/partner/extend-trial",
  PARTNER_CREDITS: "/partner/credits",
  PARTNER_COMMISSIONS: "/v1/partner/commissions",

  // Assist
  COMPLETE_TEXT_STREAM: "/v1/assist/completion/stream",
  TRANSCRIBE: "/v1/assist/transcribe",
  ASSIST_LOG: "/v1/assist-logs",

  // Deals
  PIPELINES: "/v3/pipelines",
  DEALS: "/v3/deals",
  ACTIVITIES: "/v3/activities",
  PRODUCTS: "/v3/products",
  TASKS: "/v3/tasks",

  // Workflow
  WORKFLOW: "/v3/workflows",
  WORKFLOW_LOGS: "/v3/workflows/logs",

  // Misc
  SEARCH: "/search",
  PROXY: "/v1/proxy/exec-request",
  CONVERSATION_CHAT_DELIVERY: "/v1/conversations/statuses",
  NEWS: "/v3/news",
  WARNINGS: "/v3/warnings",
  USER_INFO: "/v3/user-info",
  NOTIFICATIONS: "/v3/notifications",
  SEND_WELCOME_MESSAGE: "/v3/workspaces/send-welcome-message",
  WIDGET_SETTINGS: "/v3/widget/settings",
}

const exports = {
  domain,
  ...endpoints,
}

export default exports
