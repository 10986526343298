import { endpoints } from "config/endpoints.config"
import request from "lib/request"
import { DashboardReport, DashboardReportResult, DealMetric, mountReportTotalSingleQuery } from "models/dashboards"
import { useQuery } from "react-query"

interface useGetDashboardConversionTotalsProps {
  report?: Partial<DashboardReport>
  reportData?: DashboardReportResult[]
}

export const mountReportTotalConversionQuery = (props: { report: Partial<DashboardReport> | undefined }) => {
  return {
    ...mountReportTotalSingleQuery(
      props.report as DashboardReport,
      { metric: DealMetric.COUNT, extraParams: { ...props.report?.extraParams, hasDeal: true } },
      "conversion_deals",
    ),
    ...mountReportTotalSingleQuery(
      props.report as DashboardReport,
      { metric: DealMetric.COUNT, extraParams: { ...props.report?.extraParams, hasWonDeal: true } },
      "conversion_deals_won",
    ),
    ...mountReportTotalSingleQuery(
      props.report as DashboardReport,
      { metric: DealMetric.COUNT, extraParams: { ...props.report?.extraParams, hasDeal: false } },
      "conversion_deals_not",
    ),
    // conversion_deals: {
    //   ...props.report,
    //   id: "conversion_deals",
    //   name: "conversion_deals",
    //   extraParams: {
    //     ...props.report?.extraParams,
    //     hasDeal: true,
    //   },
    // },
    // conversion_deals_won: {
    //   ...props.report,
    //   id: "conversion_deals_won",
    //   name: "conversion_deals_won",
    //   extraParams: {
    //     ...props.report?.extraParams,
    //     hasWonDeal: true,
    //   },
    // },
  }
}

function useGetDashboardConversionTotals(props: useGetDashboardConversionTotalsProps) {
  const reportState = useQuery<Record<string, DashboardReportResult[]>>({
    queryKey: ["dashboard-reports", "conversions", props.report],
    queryFn: () =>
      request
        .post(`${endpoints.DASHBOARDS}/reports`, {
          reports: mountReportTotalConversionQuery({ report: props.report }),
        })
        .then((res) => res.data),
    enabled: (props.reportData?.[0]?.value || 0) > 0,
  })

  return reportState
}

export default useGetDashboardConversionTotals
