import { Button, Drawer, Dropdown, Menu } from "antd"
import React, { useState } from "react"
import SegmentDropdown from "components-old/shared/table/SegmentDropdown"
import { twMerge } from "tailwind-merge"
import { Workflow, WorkflowLog } from "models/workflows"
import AnalyticsCore, { getWorkflowStatusLabel } from "./AnalyticsCore"
import { Csv02Icon, Download02Icon, RefreshIcon, Xls02Icon } from "components-old/icons"
import Tooltip from "components-old/Tooltip"
import SubMenu from "antd/lib/menu/SubMenu"
import { getResourcesToExportPaginated } from "lib/export.helper"
import { endpoints } from "config/endpoints.config"
import { exportArray, formatDateBarTime } from "lib/helper"
import toast from "lib/toast"
import { WorkflowTriggerDefs } from "../actions/workflow.triggers.defs"

export interface AnalyticsDrawerProps {
  onCancel: () => void
  workflow?: Workflow
  visible: boolean
  adminMode?: boolean
}

export const fetchAndExportExecutions = async (config: { xlsx?: boolean; workflowId: string; params: any }) => {
  const { result, hideToast } = await getResourcesToExportPaginated<WorkflowLog>(`${endpoints.WORKFLOW}/${config.workflowId}/logs`, {
    params: config.params,
  })

  try {
    let items: Record<string, any>[] = []
    for (let index = 0; index < result.length; index++) {
      let receivedData = {}
      const item = result[index]
      try {
        receivedData = JSON.parse(item.trigger?.data || "{}")
      } catch (error) {
        receivedData = item.trigger?.data || {}
      }
      const itemFormatted = {
        ID: item.id,
        Status: getWorkflowStatusLabel(item.status),
        "Data de início": formatDateBarTime(item.startedAt),
        "Data de término": formatDateBarTime(item.endedAt),
        Duração: (item.duration || 0) + "ms",
        "Operações consumidas": item.operationsConsumed,
        "Ações executadas": item.trail?.map((t) => `${t.actionId}`).join(" > "),
        "Tipo de gatilho": WorkflowTriggerDefs.TriggerRecord[item.trigger.type]?.name,
        "Dados recebidos": receivedData,
        "Erro": item?.error ? `(Ação #${item?.error?.onActionId}) ${item?.error?.message} - ${item?.error?.details?.message || ""}` : "",
      }
      items.push(itemFormatted)
    }

    exportArray(items, `relatorio_execucoes_${config.workflowId}`, config)
  } catch (error) {
    console.log(error)
    toast.error("Ocorreu um erro ao exportar os dados")
  }

  hideToast()
}

function AnalyticsDrawer(props: AnalyticsDrawerProps) {
  const [maxDays, setMaxDays] = useState(30)
  const [status, setStatus] = useState("")
  const [refreshing, setRefreshing] = useState(false)

  return (
    <Drawer
      title={
        <div className="flex items-center justify-between w-full">
          <div className="flex-1 text-lg">Analítico</div>
          <div className="flex gap-2">
            <Tooltip title="Atualizar">
              <Button
                className="rounded-xl"
                icon={<RefreshIcon />}
                loading={refreshing}
                onClick={() => {
                  setRefreshing(true)
                }}
              />
            </Tooltip>
            <SegmentDropdown<any>
              title="Período"
              selected={maxDays as any}
              setSelected={(value) => {
                setMaxDays(value as any)
              }}
              segments={
                [
                  {
                    value: 1,
                    label: "Hoje",
                  },
                  {
                    value: 7,
                    label: "Últimos 7 dias",
                  },
                  {
                    value: 15,
                    label: "Últimos 15 dias",
                  },
                  {
                    value: 30,
                    label: "Últimos 30 dias",
                  },
                ] as any
              }
            />
            <SegmentDropdown<any>
              title="Status"
              selected={status}
              setSelected={(value) => {
                setStatus(value as any)
              }}
              segments={[
                {
                  value: "",
                  label: "Tudo",
                },
                {
                  value: "pending",
                  label: "Pendente",
                },
                {
                  value: "awaiting",
                  label: "Aguardando",
                },
                {
                  value: "running",
                  label: "Executando",
                },
                {
                  value: "failed",
                  label: "Falhou",
                },
                {
                  value: "done",
                  label: "Concluído",
                },
              ]}
            />
            <Dropdown
              overlay={
                <Menu>
                  <SubMenu
                    key="export"
                    icon={
                      <i>
                        <Download02Icon />
                      </i>
                    }
                    title="Exportar"
                  >
                    <Menu.Item
                      key="export-csv"
                      icon={
                        <i>
                          <Csv02Icon />
                        </i>
                      }
                      onClick={() => {
                        fetchAndExportExecutions({
                          xlsx: false,
                          workflowId: props.workflow?.id!,
                          params: {
                            maxDays,
                            status,
                          },
                        })
                      }}
                    >
                      CSV
                    </Menu.Item>
                    <Menu.Item
                      key="export-xlsx"
                      icon={
                        <i>
                          <Xls02Icon />
                        </i>
                      }
                      onClick={async () => {
                        fetchAndExportExecutions({
                          xlsx: true,
                          workflowId: props.workflow?.id!,
                          params: {
                            maxDays,
                            status,
                          },
                        })
                      }}
                    >
                      XSLX
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button icon={<i className="far fa-ellipsis-h" />} className="rounded-xl" />
            </Dropdown>
          </div>
        </div>
      }
      className="g_trigger_modal no-mask"
      visible={props.visible}
      footer={null}
      // closable={false}
      closeIcon={<i className={twMerge("far fa-times")} />}
      width={Math.min(window.innerWidth - 100, 1024)}
      bodyStyle={{
        minHeight: 400,
        overflow: "hidden",
        paddingRight: 0,
        paddingLeft: 0,
      }}
      maskClosable
      maskStyle={{
        transition: "none",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      // onCancel={props.onCancel}
      onClose={props.onCancel}
      zIndex={30}
    >
      {props.visible && (
        <AnalyticsCore {...props} status={status} maxDays={maxDays} refreshing={refreshing} setRefreshing={setRefreshing} />
      )}
    </Drawer>
  )
}

export default AnalyticsDrawer
