import { DatePicker, DatePickerProps } from "antd"
import moment from "moment"
import { Moment } from "moment"
import React, { useEffect, useState } from "react"

type DatetimeInputProps = {
  value?: Date
  onChange?: (value: string) => void
} & DatePickerProps

function DatetimeInput(props: DatetimeInputProps) {
  const [value, setValue] = useState<Moment | null>()

  useEffect(() => {
    try {
      const momentValue = props.value ? moment(props.value, "DD/MM/YYYY HH:mm") : null
      setValue(momentValue)
    } catch (e) {
      setValue(null)
    }
  }, [props.value])

  return <DatePicker {...props} value={value} onChange={(v) => props.onChange?.(v?.format("DD/MM/YYYY HH:mm") || "")} />
}

export default DatetimeInput
