import React, { useEffect, useMemo, useState } from "react"
import { Button, Dropdown, Menu, Statistic } from "antd"
import { TablePage } from "components-old/shared/table/TablePage"
import useInfiniteQueryGeneric from "hooks/useInfiniteQueryGeneric"
import { WorkflowLog, WorkflowRunStatus } from "models/workflows"
import { ColumnDef } from "@tanstack/react-table"
import { twMerge } from "tailwind-merge"
import { WorkflowTriggerDefs } from "../actions/workflow.triggers.defs"
import { CancelCircleIcon, Copy02Icon, Spin05Icon, ViewIcon } from "components-old/icons"
import { colors } from "lib/colors"
import { TagItem } from "components-old/chat/TagInput"
import Tooltip from "components-old/Tooltip"
import moment from "moment"
import { formatDateBarTime, showInfoModal } from "lib/helper"
import AnalyticsChart, { AnalyticsChartProps } from "./AnalyticsChart"
import toast from "lib/toast"
import AnalyticsExecutionModal from "./AnalyticsExecutionModal"
import request from "lib/request"
import { endpoints } from "config/endpoints.config"
import { AnalyticsDrawerProps } from "./AnalyticsDrawer"
import { coolNumberOrDash } from "lib/masks"
import Separator from "components/navigation/Separator"

export interface AnalyticsCoreProps extends AnalyticsDrawerProps {
  maxDays: number
  status: string
  refreshing: boolean
  setRefreshing: (value: boolean) => void
}

export const getWorkflowStatusLabel = (status: string) => {
  switch (status) {
    case WorkflowRunStatus.PENDING:
      return "Pendente"
    case WorkflowRunStatus.AWAITING:
      return "Aguardando"
    case WorkflowRunStatus.RUNNING:
      return "Executando"
    case WorkflowRunStatus.DONE:
      return "Concluído"
    case WorkflowRunStatus.FAILED:
      return "Falhou"
    default:
      return "Desconhecido"
  }
}

// let interval
function AnalyticsCore(props: AnalyticsCoreProps) {
  const [sorting, setSorting] = useState<any>()
  // const [maxDays, setMaxDays] = useState(30)
  // const [status, setStatus] = useState("")
  const [executionLog, setExecutionLog] = useState<WorkflowLog | null>(null)
  // const setModal = useSetRecoilState(modalState)
  const [todayReport, setTodayReport] = useState<AnalyticsChartProps["todayReport"]>({
    pending: 0,
    awaiting: 0,
    running: 0,
    done: 0,
    failed: 0,
    operationsConsumed: 0,
  })

  // const setSearchDebounced = useMemo(
  //   () =>
  //     throttle((value) => {
  //       setSearch(value)
  //     }, 100),
  //   []
  // )

  const {
    total,
    flatData,
    fetchNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    isFetched,
    refetch,
    addLocal,
    updateLocal,
    removeLocal,
    setAllLocal,
    invalidate,
  } = useInfiniteQueryGeneric<WorkflowLog>({
    key: ["workflow", "analytics", props.workflow?.id!, sorting?.[0]?.id, sorting?.[0]?.desc],
    enabled: (props.visible && !!props.workflow?.id) || props.adminMode,
    endpoint: props.adminMode ? `${endpoints.WORKFLOW}/admin-logs` : `${endpoints.WORKFLOW}/${props.workflow?.id}/logs`,
    params: {
      maxDays: props.maxDays,
      status: props.status,
      ...(sorting
        ? {
            sortBy: sorting?.[0]?.id,
            sort: sorting?.[0]?.desc ? "descend" : "ascend",
          }
        : {}),
    },
    paginated: true,
    // onSuccess: props.onSuccess,
    // params: {
    //   conversationId: props.conversationId,
    // },
    // enabled: !!props.conversationId,
    initialData: {
      pages: [
        {
          data: [],
          nextPage: 0,
          total: -1,
        },
      ],
      pageParams: [null],
    },
    options: {
      cacheTime: 1,
    },
  })

  useEffect(() => {
    if (props.refreshing) {
      invalidate().finally(() => {
        props.setRefreshing(false)
      })
    }
  }, [props.refreshing])

  useEffect(() => {
    return () => {
      setAllLocal([])
    }
  }, [])

  useEffect(() => {
    if (isFetching && props.workflow) {
      if (props.adminMode) {
        request.get(`${endpoints.WORKFLOW}/today-report-admin`).then((response) => {
          setTodayReport(response.data)
        })
      } else {
        request.get(`${endpoints.WORKFLOW}/${props.workflow._id}/today-report`).then((response) => {
          setTodayReport(response.data)
        })
      }
    }
  }, [props.visible, props.workflow, props.adminMode, isFetching])

  // useEffect(() => {
  //   if (props.workflow && props.visible) {
  //     if (isFetched && !isFetching) {
  //       refetch()
  //     }
  //     clearInterval(interval)
  //     interval = setInterval(() => {
  //       refetch()
  //       request.get(`${endpoints.WORKFLOW}/${props.workflow?._id}/today-report`).then((response) => {
  //         setTodayReport(response.data)
  //       })
  //     }, 1000 * 10)
  //   } else {
  //     clearInterval(interval)
  //   }

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [isFetched, isFetching, props.visible, props.workflow, refetch])

  // const dataFiltered = useMemo(() => {
  //   // if (maxDays < 30) {
  //   //   return flatData.filter((log) => {
  //   //     const startedAt = new Date(log.startedAt)
  //   //     const diff = new Date().getTime() - startedAt.getTime()
  //   //     const days = diff / (1000 * 60 * 60 * 24)
  //   //     return days <= maxDays
  //   //   })
  //   // }
  //   return flatData
  // }, [flatData])

  const counts = useMemo(() => {
    let counts = {
      pending: 0,
      running: 0,
      done: 0,
      failed: 0,
      operationsConsumed: 0,
    }

    const reportDays = [...(props.workflow?.reportDays || [])].reverse().slice(0, props.maxDays - 1)

    for (let index = 0; index < reportDays.length; index++) {
      const day = reportDays[index]
      counts.done += day.done || 0
      counts.failed += day.failed || 0
      counts.operationsConsumed += day.operations || 0
    }

    counts.running = todayReport.running || 0
    counts.pending = todayReport.pending || 0
    counts.done += todayReport.done || 0
    counts.failed += todayReport.failed || 0
    counts.operationsConsumed += todayReport.operationsConsumed || 0

    return counts
  }, [
    props.maxDays,
    props.workflow?.reportDays,
    todayReport.done,
    todayReport.failed,
    todayReport.operationsConsumed,
    todayReport.pending,
    todayReport.running,
  ])

  const columns: ColumnDef<WorkflowLog, any>[] = useMemo(
    () =>
      [
        {
          header: `Gatilho ${(total || 0) > 0 ? `(${total})` : ""}`,
          accessorKey: "name",
          cell: (info) => {
            const log = info?.row.original
            const triggerType = log?.trigger?.type
            const triggerRecord = WorkflowTriggerDefs.TriggerRecord[triggerType]
            const Icon: any = triggerRecord.icon
            return (
              <div className="flex items-center gap-2.5">
                <Icon className="text-teal-600 !w-5 !h-5" />
                <div className="leading-[18px] font-normal">
                  <div className="font-normal text-[13px] truncate">{triggerRecord?.name}</div>
                  <div className="text-3xs font-normal text-content-300">#{log._id}</div>
                </div>
              </div>
            )
          },
          size: 250,
        },
        {
          header: "Status",
          accessorKey: "status",
          cell: (info) => {
            const log = info?.row.original
            let statusInfo: any = {
              palette: "sky",
              label: "",
              icon: null,
            }
            switch (log.status) {
              case WorkflowRunStatus.FAILED:
                statusInfo = {
                  palette: "red",
                  label: "Falhou",
                  icon: null,
                }
                break
              case WorkflowRunStatus.DONE:
                statusInfo = {
                  palette: "teal",
                  label: "Executado",
                  icon: null,
                }
                break
              case WorkflowRunStatus.PENDING:
                statusInfo = {
                  palette: "amber",
                  label: "Pendente",
                  icon: null,
                }
                break
              case WorkflowRunStatus.AWAITING:
                statusInfo = {
                  palette: "amber",
                  label: "Aguardando",
                  icon: null,
                }
                break
              case WorkflowRunStatus.RUNNING:
                statusInfo = {
                  palette: "amber",
                  label: "Executando",
                  icon: (
                    <Spin05Icon
                      style={{
                        color: colors.amber[900],
                      }}
                      className="w-[14px] h-[14px]"
                    />
                  ),
                }
                break

              default:
                break
            }

            const color = colors[statusInfo.palette]

            return (
              <div className="flex items-center gap-2.5">
                {log.status === WorkflowRunStatus.DONE ? (
                  <i
                    className="fas fa-check-circle"
                    style={{
                      // backgroundColor: color[statusInfo.palette === "amber" ? 200 : 100],
                      // color: "#1cb08a",
                      color: color[500],
                      fontSize: 20,
                    }}
                  />
                ) : (
                  <TagItem
                    colors={{
                      bg: color[statusInfo.palette === "amber" ? 200 : 200],
                      text: color[950],
                      // bg: color[200],
                      border: color[100],
                      500: color[500],
                    }}
                  >
                    <div className={twMerge("flex items-center gap-2", statusInfo.palette === "amber" && "text-amber-950 font-medium")}>
                      {statusInfo.icon}
                      {statusInfo.label}
                    </div>
                  </TagItem>
                )}
              </div>
            )
          },
          size: 120,
        },
        {
          header: "Início",
          accessorKey: "startedAt",
          cell: (info) => {
            const log = info?.row.original
            const startedAt = new Date(log?.startedAt)
            return <Tooltip title={formatDateBarTime(log?.startedAt)}>há {moment(startedAt).fromNow(true)}</Tooltip>
          },
          size: 120,
        },
        {
          header: "Duração",
          accessorKey: "duration",
          cell: (info) => {
            const log = info?.row.original
            let duration = log?.duration || 0

            return (
              <span
                style={
                  duration > 1000
                    ? {
                        color: "#e74c3c",
                      }
                    : {}
                }
              >
                {coolNumberOrDash(duration) + "ms"}
              </span>
            )

            // if (!log?.duration) {
            //   const startedAt = new Date(log?.startedAt)
            //   duration = new Date().getTime() - startedAt.getTime()
            // }

            // const durationFormatted = formatDistanceStrict(0, duration, {
            //   locale: ptLocale,
            // })

            // return durationFormatted
          },
          size: 120,
        },
        {
          header: "Operações",
          accessorKey: "operationsConsumed",
          cell: (info) => {
            const log = info?.row.original
            return (
              <Tooltip title="Operações consumidas nessa execução do cenário.">
                {log.status === WorkflowRunStatus.RUNNING ? "" : log?.operationsConsumed}
              </Tooltip>
            )
          },
          size: 110,
        },
        ...(props.adminMode
          ? [
              {
                header: "Ambiente",
                accessorKey: "workspaceId",
                cell: (info) => {
                  const log = info?.row.original
                  return log.workspaceId
                },
                size: 110,
              },
            ]
          : []),
        {
          id: "actions-column",
          enablePinning: true,
          maxSize: 56,
          size: 56,
          cell: (info) => {
            const row = info?.row.original
            return (
              <div className="absolute right-2.5">
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(e) => {
                        e.domEvent.stopPropagation()
                      }}
                    >
                      <Menu.Item
                        key="view"
                        icon={
                          <i>
                            <ViewIcon />
                          </i>
                        }
                        onClick={() => {
                          setExecutionLog(row)
                        }}
                      >
                        Ver detalhes
                      </Menu.Item>
                      <Menu.Item
                        key="copyid"
                        icon={
                          <i>
                            <Copy02Icon />
                          </i>
                        }
                        onClick={() => {
                          navigator.clipboard.writeText(row._id)
                          toast.info("Copiado para a área de transferência")
                        }}
                      >
                        Copiar ID
                      </Menu.Item>
                      <Separator />
                      <Menu.Item
                        key="stop"
                        icon={
                          <i>
                            <CancelCircleIcon />
                          </i>
                        }
                        onClick={() => {
                          showInfoModal({
                            description: "Tem certeza que deseja cancelar essa execução?",
                            onConfirm: () => {
                              toast.info("Execução cancelada.")
                            },
                            okText: "Sim",
                            title: "Cancelar execução",
                          })
                        }}
                        disabled={row.status === WorkflowRunStatus.DONE || row.status === WorkflowRunStatus.FAILED}
                      >
                        Parar
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  className={""}
                >
                  <Button
                    icon={<i className={"fal fa-ellipsis"} style={{ fontSize: 22 }} />}
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    className="ml-auto hover:bg-base-400 focus-within:bg-base-400 rounded-xl invisible mobile:visible [tr:hover_&]:visible [tr:focus-within_&]:visible"
                  />
                </Dropdown>
              </div>
            )
          },
        },
      ] as ColumnDef<WorkflowLog>[],
    [total],
  )

  return (
    <div>
      {props.visible && (
        <TablePage
          // {...rest}
          onSuccess={(payload) => {
            // setAll(payload.pages[0].data)
            // setTotalCount(payload.pages[0].total)
          }}
          cardLayoutClassName="p-0 m-0 shadow-none max-w-full h-full dark:bg-base-200"
          tableProps={{
            data: flatData,
            totalCount: total ?? 0,
            isFetching,
            isLoading,
            isFetchingNextPage,
            // data: [],
            // totalCount: 0,
            // isFetching: true,
            // isLoading: true,
            fetchNextPage,
            columns,
            onClickRow: (row) => {
              setExecutionLog(row)
            },
            isFiltering: false,
            transparentHeader: true,
            local: false,
            className: "px-5 h-[calc(100vh-70px)] pb-5",
            sorting,
            onSortChange: (sorting) => {
              setSorting(sorting)
            },
          }}
        >
          <AnalyticsChart todayReport={todayReport} workflow={props.workflow} maxDays={props.maxDays} />
          <div className="flex gap-4 w-full my-5">
            <Statistic
              title="Executando agora"
              value={counts.running}
              className="bg-base-200 p-4 rounded-xl flex-1 dark:bg-base-100"
              valueStyle={{
                fontWeight: 500,
              }}
            />
            <Statistic
              title="Falhas"
              value={counts.failed}
              valueStyle={{
                color: "#e74c3c",
                fontWeight: 500,
              }}
              className="bg-base-200 p-4 rounded-xl flex-1 dark:bg-base-100"
            />
            <Tooltip title="Execuções concluídas com sucesso">
              <Statistic
                title="Concluídos"
                valueStyle={{
                  fontWeight: 500,
                }}
                value={counts.done}
                className="bg-base-200 p-4 rounded-xl flex-1 dark:bg-base-100"
              />
            </Tooltip>
            <Statistic
              title="Operações"
              value={counts.operationsConsumed}
              className="bg-base-200 p-4 rounded-xl flex-1 dark:bg-base-100"
              valueStyle={{
                fontWeight: 500,
              }}
            />
          </div>
        </TablePage>
      )}
      <AnalyticsExecutionModal
        executionLog={executionLog}
        workflow={props.workflow!}
        onCancel={() => {
          setExecutionLog(null)
        }}
        adminMode={props.adminMode}
      />
    </div>
  )
}

export default AnalyticsCore
