import { DatePicker, DatePickerProps } from "antd"
import moment from "moment"
import { Moment } from "moment"
import React, { useEffect, useState } from "react"

type DayInputProps = {
  value?: Date
  onChange?: (value: string) => void
} & DatePickerProps

function DayInput(props: DayInputProps) {
  const [value, setValue] = useState<Moment | null>()

  useEffect(() => {
    try {
      const momentValue = props.value ? moment(props.value) : null
      setValue(momentValue)
    } catch (e) {
      setValue(null)
    }
  }, [props.value])

  // return <DatePicker {...props} value={value} onChange={(v) => props.onChange?.(v?.format("DD/MM/YYYY") || "")} />
  return <DatePicker {...props} value={value} onChange={props.onChange} />
}

export default DayInput
