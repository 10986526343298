import { useBreadcrumb } from "hooks"
import React from "react"

interface PageProps {
  children: React.ReactNode
  title?: string
}

function Page(props: PageProps) {
  useBreadcrumb({ title: props.title })

  return <>{props.children}</>
}

export default Page
