import { getDefaultThemeBySkin, getHost, isMolaSkin } from "lib/helper"
import { Warning } from "models/warnings"
import { VariableCategory, VariableItem } from "pages/settings/workflows/workflow.defs"
import { CategoryEnum, VariableEnum } from "pages/settings/workflows/workflow.enums"
import { View } from "react-big-calendar"
import { atom, Snapshot } from "recoil"

export const localStorageEffect =
  (key: any) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const modalState = atom<React.ReactNode>({
  key: "ModalState",
  default: undefined,
})

export const modalLevel2Atom = atom<React.ReactNode>({
  key: "modalLevel2Atom",
  default: undefined,
})

export type Theme = "dark" | "light" | "noir" | "blue" | "purple" | "port"

export const themeAtom = atom<Theme>({
  key: "themeAtom",
  default: getDefaultThemeBySkin(),
  effects_UNSTABLE: [localStorageEffect("theme")],
})

export const appLoaderAtom = atom<boolean>({
  key: "appLoaderAtom",
  default: false,
})

export const lastDashboardSnapshotIdAtom = atom<string | null>({
  key: "lastDashboardSnapshotIdAtom",
  default: null,
})

export const themeIconsAtom = atom<"rounded" | "flat">({
  key: "themeIconsAtom",
  default: "flat",
  effects_UNSTABLE: [localStorageEffect("themeIconsLocal")],
})

export type IDiceAvatar =
  | "open-peeps"
  | "initials"
  | "adventurer"
  | "adventurer-neutral"
  | "avataaars"
  | "big-ears"
  | "big-ears-neutral"
  | "big-smile"
  | "bottts"
  | "croodles"
  | "croodles-neutral"
  | "micah"
  | "miniavs"
  | "personas"
  | "pixel-art"
  | "pixel-art-neutral"
  | "thumbs"
  | "notionists"
  | "lorelei"
  | "avataaars-neutral"

interface IDiceAvatarWithLabel {
  label: string
  type: IDiceAvatar
}

export const diceAvatars: IDiceAvatarWithLabel[] = [
  {
    type: "initials",
    label: "Iniciais",
  },
  {
    type: "open-peeps",
    label: "Peep",
  },
  {
    type: "avataaars",
    label: "Avataaars",
  },
  {
    type: "micah",
    label: "Micah",
  },
  {
    type: "miniavs",
    label: "Miniavs",
  },
  {
    type: "personas",
    label: "Personas",
  },
  {
    type: "avataaars-neutral",
    label: "Nariz grande",
  },
  {
    type: "lorelei",
    label: "Lorelei",
  },
  {
    type: "notionists",
    label: "Notionists",
  },
  {
    type: "thumbs",
    label: "Thumbs",
  },
]

export const themeAvatarAtom = atom<IDiceAvatar>({
  key: "themeAvatarAtom",
  default: "initials",
  effects_UNSTABLE: [localStorageEffect("themeAvatarLocal")],
})

export const sideOpenAtom = atom<boolean>({
  key: "sideOpenAtom",
  default: getHost().subdomain == "mobile" || window.innerWidth <= 768 ? false : true,
  effects_UNSTABLE: [localStorageEffect("side-open")],
})

export const randomKeyAtom = atom<string | null>({
  key: "randomKeyAtom",
  default: null,
})

export const isMobileAtom = atom<boolean>({
  key: "isMobileAtom",
  default: false,
})

export const reconnectedCountAtom = atom<number>({
  key: "reconnectedCountAtom",
  default: 0,
})

export const imageViewerAtom = atom<{
  src: string
  alt: string
  downloadUrl: string
} | null>({
  key: "imageViewerAtom",
  default: null,
})

export const userInfoAtom = atom<{ newsUnreads: number; userId: number; notificationsUnreads: number } | undefined>({
  key: "userInfoAtom",
  default: undefined,
})

export const masterLoadedAtom = atom<boolean>({
  key: "masterLoadedAtom",
  default: false,
})

export interface WorkspaceSessions {
  sessions: {
    [token: string]: {
      sessionCreatedAt: string
      device: string
      ip: string
      isMobile: boolean
      sessionId: string
    }
  }
}

export const sessionsLimitReachedAtom = atom<WorkspaceSessions | undefined>({
  key: "sessionsLimitReachedAtom",
  default: undefined,
})

export const calendarPeriodViewAtom = atom<View>({
  key: "calendarPeriodViewAtom",
  default: "day",
  effects_UNSTABLE: [localStorageEffect("calendar-period-view")],
})

export const warningsAtom = atom<null | Warning>({
  key: "warningsAtom",
  default: null,
})

export const warningsHiddenAtom = atom<Record<string, boolean>>({
  key: "warningsHiddenAtom",
  default: {},
  effects_UNSTABLE: [localStorageEffect("hidden-warnings")],
})

export const adminAtom = atom<{ masterPassword?: string; isSystemAdmin?: boolean; loading?: boolean }>({
  key: "adminAtom",
  default: {
    loading: true,
  },
})

export const assistantStreamTextAtom = atom<string>({
  key: "assistantStreamTextAtom",
  default: "",
})

export const slaveWorkspaceCountsAtom = atom<Record<string, number>>({
  key: "slaveWorkspaceCountsAtom",
  default: {},
})

export const variableAtom = atom<{
  variablesRecord: Record<VariableEnum, VariableItem>
  categoryWithVariablesRecord: Record<CategoryEnum, VariableCategory & { variables: VariableItem[] }>
}>({
  key: "variableAtom",
  default: {
    variablesRecord: {} as any,
    categoryWithVariablesRecord: {} as any,
  },
})
