import { Form, FormProps as F } from "antd"
import { cn } from "lib/utils"

interface FormProps extends F {
  children?: React.ReactNode
}

export function DefaultForm({ children, ...props }: FormProps) {
  return (
    <Form layout="vertical" {...props} className={cn(props.className)}>
      {children}
    </Form>
  )
}

export default DefaultForm
