import React, { useCallback } from "react"
import { Button, Col, Form, Menu, Row, Tooltip, Spin } from "antd"
import TableActionsDropdown from "components-old/common/TableActionsDropdown"
import { Link, RouteComponentProps, useHistory } from "react-router-dom"
import { exportArray, showDeleteModal, verifyPermission } from "lib/helper"
import Truncate from "components-old/common/Truncate"
import { useGenericStore, useStore, useStoreActions, useStoreState } from "hooks"
import MenuLink from "components-old/menu/MenuLink"
import SearchInput from "components-old/common/SettingsPage/SearchInput"
import { IChannel, IChannelStatus } from "models/channels"
import EditChannel from "./EditChannel"
import { channelsTypes, CHANNEL_TYPES, WhatsappVersion } from "lib/constants"
import masks from "lib/masks"
import Avatar from "components-old/ui/Avatar"
import wppImg from "assets/images/channels/whatsapp.svg"
import instagramImg from "assets/images/channels/instagram.svg"
import mlImg from "assets/images/channels/mercado-livre.png"
import telegramImg from "assets/images/channels/telegram.svg"
import facebookRoundImg from "assets/images/channels/facebook-round.svg"
import emailImg from "assets/images/channels/email.svg"
import smsImg from "assets/images/channels/sms.png"
import widgetImg from "assets/images/channels/widget.svg"
import customImg from "assets/images/channels/custom.svg"
import copy from "copy-to-clipboard"
import toast from "lib/toast"
import ListPage, { IColumnProps } from "components-old/common/ListPage"
import { useRecoilState, useSetRecoilState } from "recoil"
import { isMobileAtom, modalState } from "atoms/app.atom"
import _ from "lodash"
import request from "lib/request"
import { endpoints } from "config/endpoints.config"
import MenuSeparator from "components-old/ui/Menu/MenuSeparator"
import { IChannelWhatsApp } from "models/channelsWhatsapp"
import SyncZapHistoryModal from "./whatsapp/SyncZapHistoryModal"
import { general, session } from "store"
import ForwardZapModal from "./whatsapp/ForwardZapModal"
import { IWorkspace } from "models/workspaces"
import SubMenu from "antd/lib/menu/SubMenu"
import { formatCustomInputValue } from "lib/formatInputValue"
import { channelIsInUserGroups } from "components-old/ui/ChannelsSelect"
import Separator from "components/navigation/Separator"

export const ChannelStatus = (props: { status?: IChannelStatus; truncateMobile?: boolean; channel?: IChannel }) => {
  // const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  if (!props.status) {
    return <span />
  }
  let status2
  try {
    const isNotUpdated =
      !props.channel?.lastCreatedAt || new Date(props.channel?.lastCreatedAt!) < new Date("2023-01-09 00:00:00.740 -0300")
    if (props.channel?.channelId == CHANNEL_TYPES.WHATSAPP && props.channel?.version == WhatsappVersion.BAILEYS && isNotUpdated) {
      status2 = (
        <Tooltip
          title={`Canal desatualizado. Recomendado recriar. Vá no seu canal, clique em "ações" e em Recriar canal. Será necessário ler o QRCode novamente.`}
        >
          <span className="contract-status channels-status" style={{ backgroundColor: "rgb(255, 234, 167)", color: "#000" }}>
            {props.truncateMobile ? _.truncate("Recomendado recriar canal", { length: 10 }) : "Recomendado recriar canal"}
          </span>
          {/* {props.status == "qr_code" && !isMobile && (
                    <i className="fal fa-qrcode" style={{ fontSize: 18, marginLeft: 20 }} />
                )} */}
        </Tooltip>
      )
    }
  } catch (e) {
    console.log(e)
  }

  const statusProps = getStatus(props.status)

  if (props.status == "connected") {
    return (
      <>
        <Tooltip title={statusProps.description}>
          <i className="fas fa-badge-check" style={{ color: "#4dad55", fontSize: 20 }} />
        </Tooltip>
        {status2}
      </>
    )
  }

  return (
    <>
      <Tooltip title={statusProps.description}>
        <span className="contract-status channels-status" style={{ backgroundColor: statusProps.bg, color: statusProps.color || "white" }}>
          {props.truncateMobile ? _.truncate(statusProps.title, { length: 10 }) : statusProps.title}
        </span>
        {/* {props.status == "qr_code" && !isMobile && (
                <i className="fal fa-qrcode" style={{ fontSize: 18, marginLeft: 20 }} />
            )} */}
      </Tooltip>
      {status2}
    </>
  )
}

export function getProps(item?: Partial<IChannel>, workspace?: IWorkspace) {
  // console.log("workspace", workspace?.sms_credits)

  if (item?.channelId == CHANNEL_TYPES.SMS) {
    return {
      name: "SMS",
      description: (workspace?.sms_credits || 0) + " créditos",
      img: smsImg,
      to: "/settings/channels/sms/" + item?.id,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.WIDGET) {
    return {
      name: "Widget",
      description: "",
      img: widgetImg, //"https://cdn-icons-png.flaticon.com/512/736/736114.png",
      to: "/settings/channels/widget/" + item?.id,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.FACEBOOK) {
    return {
      name: item?.description,
      description: "Página do Facebook",
      img: facebookRoundImg,
      to: "/settings/channels/facebook/" + item?.id,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.WHATSAPP) {
    return {
      name: item?.description,
      description: `${masks.cell(item?.phone)}`,
      icon: (
        <i
          className="fab fa-whatsapp"
          style={{
            fontSize: 18,
            width: 30,
            height: 30,
            backgroundColor: "#3d9c4c",
            color: "#fff",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ),
      img: wppImg,
      iconStyle: {
        backgroundColor: "#f1fbf6",
      },
      to:
        item?.version == WhatsappVersion.BAILEYS
          ? "/settings/channels/whatsapp/" + item?.id
          : "/settings/channels/whatsapp-cloud-api/" + item?.id,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.INSTAGRAM) {
    return {
      name: item?.description,
      description: "Instagram Direct",
      img: instagramImg,
      iconStyle: {
        backgroundColor: "#f1fbf6",
      },
      to: "/settings/channels/instagram/" + item?.id,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.MERCADO_LIVRE) {
    return {
      name: item?.description,
      description: "Mercado Livre",
      img: mlImg,
      iconStyle: {
        backgroundColor: "#f1fbf6",
      },
      to: "/settings/channels/mercado-livre/" + item?.id,
      rounded: true,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.TELEGRAM) {
    return {
      name: item?.description,
      description: "Bot do telegram",
      img: telegramImg,
      to: "/settings/channels/telegram/" + item?.id,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.EMAIL) {
    return {
      description: `${item?.description}`,
      icon: (
        <i
          className="fad fa-envelope"
          style={{
            fontSize: 24,
            width: 28,
            color: "#f1c40f",
          }}
        />
      ),
      img: emailImg,
      to: "/settings/channels/email/" + item?.id,
      name: item?.name,
    }
  }
  if (item?.channelId == CHANNEL_TYPES.CUSTOM) {
    return {
      description: "Canal customizado",
      // icon: "fab fa-cuttlefish",
      icon: (
        <i
          className="fab fa-cuttlefish"
          style={{
            fontSize: 24,
            width: 28,
            color: "#e0cd1f",
          }}
        />
      ),
      img: customImg,
      to: "/settings/channels/custom/" + item?.id,
      name: item?.description,
    }
  }
}

export function getStatus(status: IChannelStatus) {
  switch (status) {
    case "connected":
      return {
        title: "Conectado",
        bg: "#27ae60",
        color: "white",
        description: "Seu canal está conectado e funcionando.",
      }
    case "disconnected":
      return {
        title: "Desconectado",
        bg: "#f1c40f",
        description: "Seu canal está desconectado. Verifique sua conexão de internet.",
      }
    case "processing_channel":
      return {
        title: "Criando...",
        bg: "#95a5a6",
        description: "Estamos criando seu canal, esse processo poderá demorar até 10 minutos.",
      }
    case "restarting_channel":
      return {
        title: "Reiniciando...",
        bg: "#95a5a6",
        description: "Estamos reiniciando seu canal, esse processo poderá demorar até 5 minutos.",
      }
    case "login_required":
      return {
        title: "Necessário relogar",
        bg: "#e74c3c",
        description: "Necessário reenviar suas credenciais.",
      }
    case "restart_required":
      return {
        title: "Necessário reiniciar canal",
        bg: "#e74c3c",
        description: 'Vá no seu canal, clique em "ações" e em Reiniciar canal.',
      }
    case "recreate_required":
      return {
        title: "Recomendado recriar canal",
        bg: "#ffeaa7",
        color: "#000",
        description: 'Canal desatualizado. Vá no seu canal, clique em "ações" e em Recriar canal. Será necessário ler o QRCode novamente.',
      }
    case "qr_code":
      return {
        title: "Necessário ler QR-Code",
        bg: "#e67e22",
        description: "Leia o QRCode com seu celular na página do canal para conectar.",
      }
    case "invalid_credentials":
      return { title: "Credenciais inválidos", bg: "#e74c3c" }
    case "invalid_code":
      return { title: "Código inválido", bg: "#e74c3c" }
    case "challenge_required":
      return { title: "Código requerido", bg: "#e74c3c" }
    default:
      return { title: status, bg: "#e74c3c" }
  }
}

function Channels(props: RouteComponentProps) {
  const { all: channels } = useStoreState((state) => state.channels)
  const { all: groups } = useStoreState((state) => state.groups)
  const setModal = useSetRecoilState(modalState)

  const { remove, patchElementOnAll } = useStoreActions((actions) => actions.channels)
  const customFieldsState = useGenericStore((state) => state.customFields)
  const path = "/settings/channels"
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const { addModal, removeModal } = useStore(general)
  const { workspace, user } = useStore(session)
  const isChannelsGroupLimited = verifyPermission("only_can_see_channels_if_in_channel_group", {
    restrictive: true,
  })

  const exportData = (channels: IChannel[], xls?: boolean) => {
    const data = channels.map((channel) => {
      const channelCustoms = customFieldsState.all
        ?.filter((c) => c.entity == "channel")
        .reduce((acc: any, customField) => {
          let name = customField.name
          acc[name] = formatCustomInputValue(customField.type, channel?.customFields?.[customField?.placeholder]?.value) || ""
          return acc
        }, {})
      return {
        Nome: channel.name || "",
        Descrição: channel.description || "",
        Tipo: (channelsTypes as any)[channel.channelId]?.path || "",
        Identificador: channel?.identifier || "",
        Conectado: channel?.status == "connected" ? "Sim" : "Não",
        ...channelCustoms,
      }
    })
    exportArray(data, "canais", { xlsx: xls })
  }

  const columns: IColumnProps<IChannel>[] = [
    {
      title: "",
      dataIndex: "channelId",
      render: (channelId, channel) => {
        const channelType = (channelsTypes as any)[channelId]
        const channelProps = getProps(channel, workspace)
        const avatar = channel.channelId == "6" ? channel.avatar : null
        return (
          <Spin spinning={channel.status == "processing_channel" || channel.status == "restarting_channel"}>
            <Row align="middle" style={{ flexFlow: "nowrap" }} gutter={12}>
              <Col style={{ padding: "16px 11px" }}>
                {channelProps?.icon || (
                  <Avatar
                    name={channelType.name}
                    index={channelId}
                    fontSize={12}
                    size={28}
                    imgSrc={avatar || channelProps?.img}
                    // shadow
                    notRounded={!avatar && !channelProps?.rounded}
                  />
                )}
              </Col>
              <Col style={{ lineHeight: "20px" }}>
                <Truncate maxWidth={200} title={channelType.name}>
                  {channelProps?.name}
                </Truncate>
              </Col>
            </Row>
          </Spin>
        )
      },
      span: isMobile ? 10 : 7,
    },
    {
      title: "",
      dataIndex: "channelId2",
      render: (channelId, channel) => {
        const channelProps = getProps(channel, workspace)
        return <Truncate maxWidth={146}>{channelProps?.description}</Truncate>
      },
      span: 7,
      hide: () => isMobile,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, channel) => {
        return (
          <Row justify="end" style={{ textAlign: "right", flex: 1 }} align="middle">
            {" "}
            <ChannelStatus status={status} truncateMobile={isMobile} channel={channel} />
            {channel?.isBanned && (
              <Tooltip title="Seu canal foi possivelmente bloqueado pelo WhatsApp">
                <i
                  className="fas fa-solid fa-circle-exclamation"
                  style={{
                    color: "#e74c3c",
                    fontSize: 20,
                    marginLeft: 5,
                  }}
                />
              </Tooltip>
            )}
          </Row>
        )
      },
      span: 7,
    },
    {
      key: "action",
      fixed: "right",
      span: isMobile ? 2 : 2,
      width: 60,
      render: (text, channel) => (
        <TableActionsDropdown>
          <Menu
            onClick={(e) => {
              e.domEvent.stopPropagation()
            }}
          >
            <Menu.Item
              key="copy"
              icon={<i className={"fal fa-fingerprint"} />}
              onClick={() => {
                copy(channel.id)
                toast.info(`ID do canal (${channel.id}) copiado para clipboard.`)
              }}
            >
              Copiar ID
            </Menu.Item>
            <Menu.Item key="edit">
              <MenuLink iconName={"fal fa-edit"} to={`/settings/channels/${channel.id}`}>
                Editar
              </MenuLink>
            </Menu.Item>
            <Separator />
            {channel?.channelId == CHANNEL_TYPES.WHATSAPP &&
              channel?.version != WhatsappVersion.API_CLOUD &&
              whatsappActions(channel).map((zapAction) => (
                <Menu.Item
                  key={zapAction.label}
                  icon={<i className={zapAction.icon} style={zapAction?.iconStyle}></i>}
                  onClick={zapAction.onClick}
                  disabled={zapAction.disabled}
                >
                  {zapAction?.label}
                </Menu.Item>
              ))}
            {channel?.channelId == CHANNEL_TYPES.WHATSAPP && channel?.version != WhatsappVersion.API_CLOUD && <Separator />}
            {channel?.channelId == CHANNEL_TYPES.WHATSAPP &&
              channel?.version == WhatsappVersion.API_CLOUD &&
              whatsappOfficialActions(channel).map((zapAction) => (
                <Menu.Item key={zapAction.label} icon={<i className={zapAction.icon}></i>} onClick={zapAction.onClick}>
                  {zapAction?.label}
                </Menu.Item>
              ))}
            {channel?.channelId == CHANNEL_TYPES.WHATSAPP && channel?.version == WhatsappVersion.API_CLOUD && <Separator />}

            <Menu.Item
              key="delete"
              icon={<i className={"fal fa-trash-alt"} />}
              onClick={() => {
                showDeleteModal({
                  description: "Tem certeza que deseja excluir esse canal?",
                  onConfirm: () => remove({ id: channel.id }),
                })
              }}
              disabled={isChannelsGroupLimited || channel?.status == "restarting_channel" || channel?.status == "processing_channel"}
            >
              Excluir
            </Menu.Item>
          </Menu>
        </TableActionsDropdown>
      ),
    },
  ]

  const whatsappActions = useCallback(
    (channel: IChannelWhatsApp) => [
      {
        icon: "fal fa-wifi-slash",
        iconStyle: {
          fontSize: 16,
        },
        label: "Desconectar",
        onClick: () => {
          showDeleteModal({
            title: "Desconectar",
            description: "Tem certeza que deseja desconectar esse canal?",
            onConfirm: () =>
              request.post(endpoints.WHATSAPP_LOGOUT + "/" + channel?.id).then(() => {
                toast.warning("Desconectado o canal de WhatsApp " + channel?.description)
              }),
            okText: "Desconectar",
          })
        },
        disabled: channel?.status == "processing_channel" || channel?.status == "restarting_channel",
      },
      {
        icon: "fal fa-undo",
        label: "Reiniciar",
        onClick: () => {
          if (channel?.status == "recreate_required") {
            toast.error("Necessário recriar o canal e ler o QRcode para reiniciar")
          } else {
            showDeleteModal({
              title: "Reiniciar",
              description: "Tem certeza que deseja reiniciar esse canal?",
              onConfirm: () => {
                return request.post(endpoints.RESTART_CONTAINER + "/" + channel.id)
              },
              okText: "Reiniciar",
            })
          }
        },
        // disabled: channel?.status == "restarting_channel",
      },
      {
        icon: "fal fa-cloud-download",
        label: "Sincronizar histórico",
        onClick: () => {
          addModal(<SyncZapHistoryModal item={channel} onBackModal={() => removeModal()} />)
        },
        disabled: channel?.status != "connected",
      },
      {
        icon: "fal fa-angle-double-right",
        label: "Migrar conversas",
        onClick: () => {
          setModal(<ForwardZapModal channel={channel} onBack={() => setModal(null)} />)
        },
        disabled: isChannelsGroupLimited,
      },
      {
        icon: "fal fa-power-off",
        label: "Recriar",
        onClick: () => {
          showDeleteModal({
            description:
              "Tem certeza que deseja recriar esse canal? Esse processo demora até 10 minutos, seu canal ficará indisponível durante esse tempo e você deverá ler o QRCode novamente no final do processo.",
            onConfirm: () => {
              return request
                .post(endpoints.RECREATE_CHANNEL + "/" + channel?.id, {
                  description: channel?.description,
                })
                .then((response) => {
                  patchElementOnAll(response.data)
                })
            },
            okText: "Recriar",
          })
        },
        disabled: channel?.status == "processing_channel",
      },
    ],
    [addModal, patchElementOnAll, removeModal],
  )
  const whatsappOfficialActions = useCallback(
    (channel: IChannelWhatsApp) => [
      {
        icon: "fal fa-angle-double-right",
        label: "Migrar conversas",
        onClick: () => {
          setModal(<ForwardZapModal channel={channel} onBack={() => setModal(null)} />)
        },
      },
      {
        icon: "fal fa-sync",
        label: "Atualizar registro",
        onClick: () => {
          const hide = toast.loading("Atualizando registro...")
          request.post(`${endpoints.INTEGRATIONS_REGISTER_CLOUD_API}/${channel?.id}`)?.finally(() => {
            hide()
          })
        },
      },
    ],
    [addModal, patchElementOnAll, removeModal],
  )

  return (
    <ListPage<IChannel>
      {...props}
      title="Canais"
      data={channels?.filter((c) => {
        if (isChannelsGroupLimited) {
          return c.isPrivate && channelIsInUserGroups({ channel: c, groups, workspace, user })
        }
        return true
      })}
      permission="manage_channels"
      onApplyFilters={(item, filters) => {
        if (filters.search) {
          return (
            (item.description?.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase()) ||
              (item.name || "")?.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase()) ||
              ("+" + item.identifier)?.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase())) &&
            item?.channelId !== CHANNEL_TYPES.WIDGET
          )
        }
        return item?.channelId !== CHANNEL_TYPES.WIDGET
      }}
      pageSubtitle="Conecte os apps que os clientes entrarão em contato com sua empresa"
      columns={columns.filter((x) => !x.hide || !x.hide?.())}
      pathName="channels"
      path={path}
      style={{
        width: 900,
      }}
      EditComponent={EditChannel}
      storePath={(store) => store.channels}
      pageSize={0}
      extraMore={
        isChannelsGroupLimited
          ? undefined
          : [
              {
                id: "export-root",
                custom: (
                  <SubMenu key="export" icon={<i className="fal fa-cloud-download" />} title="Exportar">
                    <Menu.Item
                      key="export-csv"
                      icon={<i className="fal fa-file-csv" />}
                      onClick={() => {
                        exportData(channels)
                      }}
                    >
                      CSV
                    </Menu.Item>
                    <Menu.Item
                      key="export-xlsx"
                      icon={<i className="fal fa-file-spreadsheet" />}
                      onClick={async () => {
                        exportData(channels, true)
                      }}
                    >
                      XSLX
                    </Menu.Item>
                  </SubMenu>
                ),
              },
            ]
      }
      helpText={
        <span>Conecte-se aos aplicativos de mensagens mais usados pelos seus clientes e responda todos em sua caixa de entrada.</span>
      }
      actions={
        isChannelsGroupLimited
          ? []
          : [
              <Link
                key="new"
                to="/settings/channels/new"
                className="global-add-button-link"
                onClick={(e) => {
                  if (isMobile) {
                    e.preventDefault()
                    e.stopPropagation()
                    toast.error("Somente é possível criar novos canais pela versão WEB.")
                  }
                }}
              >
                <Button type="primary" icon={<i className="far fa-plus" />}>
                  Novo canal
                </Button>
              </Link>,
            ]
      }
      filters={[
        <Form.Item name="search" style={{ marginBottom: 0, marginRight: 15 }} key="search">
          <SearchInput gray icon />
        </Form.Item>,
      ]}
      noData={{
        title: "Parece que você ainda não conectou nenhum canal...",
        buttonText: "Novo canal",
      }}
    />
  )
}

export default Channels
