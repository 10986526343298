import TriggerNode from "./actions/trigger/TriggerNode"
import {
  BankIcon,
  MailSend02Icon,
  Settings03Icon,
  SmartPhone01Icon,
  Target02Icon,
  WebhookIcon,
  WorkflowSquare09Icon,
} from "components-old/icons"
import { WorkflowTriggerDefs } from "./actions/workflow.triggers.defs"
import EditTrigger from "./actions/trigger/EditTrigger"
import { ColorPalette, colors } from "lib/colors"
import { ConversationWorkflowDefs } from "./entities/conversation/conversation.workflow.def"
import { CategoryEnum, VariableEnum, ActionEnum } from "./workflow.enums"
import React from "react"
import { WorkflowActionLog, WorkflowLog } from "models/workflows"
import { UtilsWorkflowDefs } from "./entities/utils/utils.workflow.def"
import { ContactWorkflowDefs } from "./entities/contact/contact.workflow.def"
import { InputType } from "lib/constants"
import { DealWorkflowDefs } from "./entities/deals/deal.workflow.def"
import { DealStatus } from "models/deals"

/**********************************
 * TYPES
 **********************************/
export type WorkflowNodeAction = {
  contact?: {
    id?: string
    name?: string
    email?: string
    phone?: string
    notes?: string
    customFields?: Record<string, { value: any }>
    contactReference?: string
  }
  request?: {
    url: string
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
    headers?: Record<string, string>
    body?: Record<string, any>
  }
  deal?: {
    name?: string
    id?: string
    contactReference?: string
    dealReference?: string
    conversationReference?: number
    status: DealStatus
    customFields?: Record<string, { value: any }>
    pipelineId?: string
    stageId?: string
  }
  conversation?: {
    contactReference?: string
    conversationReference?: number
    id?: string
    channelId: string
    operatorId: number
    groupId: number
    tagIds: number[]
    customFields?: Record<string, { value: any }>
    assign: {
      type: "queue"
      selectBy: "operator"
      operators: {
        id: string
        capacity: number
      }[]
    }
  }
  runBot?: {
    botId?: number
    conversationReference?: string
  }
  delay?: {
    duration: number
    unit: "seconds" | "minutes" | "hours" | "days"
    useBusinessHours: boolean
    businessHoursId?: string
  }
  decision?: {
    field: string
    operator: string
    value: string
  }
} & WorkflowNodeActionTrigger

export type WorkflowNodeActionTrigger = {
  triggers?: Record<ActionEnum, { active: boolean }>
}
export interface WorkflowNodeData {
  backgroundColor?: string
  action?: WorkflowNodeAction
}

export interface InputField {
  label: string
  icon?: React.ElementType
  type: InputType
  name: string
  description?: string
  isCustomField?: boolean
  selectable?: boolean
  custom?: React.ReactNode
  options?: {
    value: string
    label: string
  }[]
}

export interface WorkflowActionsItem {
  type: ActionEnum
  // palette: ColorPalette
  colors?: {
    bg?: string
    text?: string
  }
  name: string
  icon: React.ElementType
  node: React.ElementType
  isConnectable?: boolean
  isCreateable?: boolean
  isReferenceable?: boolean
  variableCategory: CategoryEnum
  edit?: React.ElementType
  getDescription?: (dto: { category: VariableCategory; actionLog?: WorkflowActionLog; executionLog: WorkflowLog }) => React.ReactNode
}

export interface WorkflowEntityDef {
  actions: WorkflowActionsItem[]
  variables: VariableItem[]
  fields?: InputField[]
  category: VariableCategory
}

export interface VariableItem {
  type: VariableEnum
  label: string
  icon?: any
  category: CategoryEnum
  primitiveType: "string" | "number" | "boolean" | "date" | "any" | "json"
  description: string
  json?: Record<string, any>
  customFieldKey?: string
}

export interface VariableCategory {
  type: CategoryEnum
  label: string
  icon: React.ElementType
  palette: ColorPalette
  paletteTone?: keyof typeof colors
}

export interface VariableMenuOption extends VariableCategory {
  variables?: VariableItem[]
  reference?: string
}

/**********************************
 * DERIVED
 **********************************/
const sections = [
  {
    label: "Sistema",
    categories: [CategoryEnum.WORKSPACE, CategoryEnum.WORKFLOW, CategoryEnum.UTILS],
  },
]

/**********************************
 * DERIVED
 **********************************/

const entityDefs: WorkflowEntityDef[] = [ConversationWorkflowDefs, ContactWorkflowDefs, DealWorkflowDefs, UtilsWorkflowDefs]

const mountWorkflowDefs = () => {
  /**********************************
   * ARRAYS
   **********************************/
  const categories: VariableCategory[] = [
    {
      type: CategoryEnum.WEBHOOK,
      label: "Webhook",
      icon: WebhookIcon,
      palette: "green",
    },
    {
      type: CategoryEnum.CAMPAIGN_MESSAGE_SENT,
      label: "Campanha",
      icon: MailSend02Icon,
      palette: "orange",
    },
    {
      type: CategoryEnum.WORKFLOW,
      label: "Cenário",
      icon: WorkflowSquare09Icon,
      palette: "gray",
    },
    {
      type: CategoryEnum.WORKSPACE,
      label: "Ambiente",
      icon: BankIcon,
      palette: "gray",
    },
    {
      type: CategoryEnum.UTILS,
      label: "Utils",
      icon: Settings03Icon,
      palette: "gray",
    },
    ...entityDefs.map((def) => def.category),
  ]

  const variables: VariableItem[] = [
    // Deleted
    {
      type: VariableEnum.DELETED,
      category: CategoryEnum.DELETED,
      label: "Excluído",
      primitiveType: "any",
      description: "A referência dessa variável foi excluída.",
    },

    // Utils
    {
      type: VariableEnum.UUID,
      category: CategoryEnum.UTILS,
      label: "uuid",
      primitiveType: "string",
      description: "Gerar um identificador único universal.",
    },
    {
      type: VariableEnum.NOW,
      category: CategoryEnum.UTILS,
      label: "Agora",
      primitiveType: "string",
      description: "Data e hora atual.",
    },
    {
      type: VariableEnum.TIMESTAMP,
      category: CategoryEnum.UTILS,
      label: "Timestamp",
      primitiveType: "number",
      description: "Unix timestamp. Número de segundos desde a meia-noite de 1º de janeiro de 1970 GMT.",
    },
    {
      type: VariableEnum.RANDOM,
      category: CategoryEnum.UTILS,
      label: "Aleatório",
      primitiveType: "number",
      description: "Um número aleatório de ponto flutuante maior ou igual a 0 e menor que 1.",
    },

    // Campanha
    {
      type: VariableEnum.CAMPAIGN_MESSAGE_SENT_NUMBER,
      category: CategoryEnum.CAMPAIGN_MESSAGE_SENT,
      label: "Telefone do lead",
      primitiveType: "string",
      description: "Número de telefone do lead para o qual a mensagem de campanha foi enviada.",
    },
    {
      type: VariableEnum.CAMPAIGN_MESSAGE_SENT_CAMPAIGN_ID,
      category: CategoryEnum.CAMPAIGN_MESSAGE_SENT,
      label: "ID",
      primitiveType: "string",
      description: "ID da campanha.",
    },
    {
      type: VariableEnum.CAMPAIGN_MESSAGE_SENT_TEXT,
      category: CategoryEnum.CAMPAIGN_MESSAGE_SENT,
      label: "Texto",
      primitiveType: "string",
      description: "Texto da mensagem da campanha.",
    },

    // Webhook
    {
      type: VariableEnum.WEBHOOK_URL,
      category: CategoryEnum.WEBHOOK,
      label: "Url",
      primitiveType: "string",
      description: "Url do webhook de recebimento.",
    },
    {
      type: VariableEnum.WEBHOOK_QUERY_PARAMS,
      category: CategoryEnum.WEBHOOK,
      label: "Query",
      primitiveType: "json",
      description: "Parâmetros de query da url do webhook.",
    },
    {
      type: VariableEnum.WEBHOOK_BODY,
      category: CategoryEnum.WEBHOOK,
      label: "Body",
      primitiveType: "json",
      description: "Payload recebido do webhook.",
    },
    {
      type: VariableEnum.WEBHOOK_ORIGIN,
      category: CategoryEnum.WEBHOOK,
      label: "Origem",
      primitiveType: "string",
      description: "Domínio de origem do webhook de onde se originou a requisição.",
    },
    // {
    //   type: VariableEnum.WEBHOOK_REFERER,
    //   category: CategoryEnum.WEBHOOK,
    //   label: "Referência",
    //   primitiveType: "string",
    //   description: "Url de referência do webhook de onde se originou a requisição.",
    // },

    // Workflow
    {
      type: VariableEnum.WORKFLOW_ID,
      category: CategoryEnum.WORKFLOW,
      label: "ID",
      primitiveType: "string",
      description: "Identificador único do cenário.",
    },
    {
      type: VariableEnum.WORKFLOW_RUN_STARTED_AT,
      category: CategoryEnum.WORKFLOW,
      label: "Data da execução",
      primitiveType: "date",
      description: "Mostra o tempo de quando o cenário foi executado.",
    },
    {
      type: VariableEnum.WORKFLOW_RUN_OPERATIONS_CONSUMED,
      category: CategoryEnum.WORKFLOW,
      label: "Operações consumidas",
      primitiveType: "number",
      description: "Mostra o número de operações consumidas na execução cenário até o momento.",
    },

    // Workspace
    {
      type: VariableEnum.WORKSPACE_ID,
      category: CategoryEnum.WORKSPACE,
      label: "ID",
      primitiveType: "number",
      description: "Identificador único do ambiente.",
    },
    {
      type: VariableEnum.WORKSPACE_NAME,
      category: CategoryEnum.WORKSPACE,
      label: "Nome",
      primitiveType: "string",
      description: "Nome do ambiente.",
    },

    ...entityDefs.flatMap((def) => def.variables),
  ]

  const actions: WorkflowActionsItem[] = [
    {
      type: ActionEnum.TRIGGER,
      name: "Quando...",
      icon: Target02Icon,
      node: TriggerNode,
      isConnectable: false,
      isCreateable: false,
      edit: EditTrigger,
      variableCategory: CategoryEnum.WEBHOOK,
    },
    // {
    //   type: "dummy" as any,
    //   name: "",
    //   icon: Target02Icon,
    //   node: DummyNode,
    //   isConnectable: false,
    //   isCreateable: false,
    //   edit: EditTrigger,
    //   variableCategory: CategoryEnum.WEBHOOK,
    // },
    ...entityDefs.flatMap((def) => def.actions),
    // {
    //   type: ActionEnum.DEAL_CREATE,
    //   palette: "teal",
    //   name: "Criar negócio",
    //   icon: SquareSingleFireIcon,
    //   node: CreateDealNode,
    //   isConnectable: true,
    //   edit: EditCreateDeal,
    // },
    // {
    //   type: ActionEnum.DEAL_UPDATE,
    //   palette: "purple",
    //   name: "Atualizar negócio",
    //   icon: SquareSingleFireIcon,
    //   node: CreateDealNode,
    //   isConnectable: true,
    // },
  ]

  /**********************************
   * RECORDS
   **********************************/
  const ActionRecord = actions.reduce((acc, item) => {
    acc[item.type] = item
    return acc
  }, {}) as Record<ActionEnum, WorkflowActionsItem>

  const CategoryRecord = categories.reduce((acc, item) => {
    acc[item.type] = item
    return acc
  }, {}) as Record<CategoryEnum, VariableCategory>

  const Fields = categories.reduce((acc, item) => {
    const def = entityDefs.find((def) => def.category.type === item.type)
    acc[item.type] = {
      record:
        def?.fields?.reduce((acc, field) => {
          acc[field.name] = field
          return acc
        }, {}) || {},
      array: def?.fields,
    }

    return acc
  }, {}) as Record<
    CategoryEnum,
    {
      record: Record<string, InputField>
      array: InputField[]
    }
  >

  const VariableRecord = variables.reduce((acc, item) => {
    acc[item.type] = item
    return acc
  }, {}) as Record<VariableEnum, VariableItem>

  const CategoryWithVariablesRecord = categories.reduce((acc, item) => {
    acc[item.type] = {
      ...item,
      variables: variables.filter((variable) => variable.category === item.type),
    }
    return acc
  }, {}) as Record<CategoryEnum, VariableCategory & { variables: VariableItem[] }>

  /**********************************
   * HELPERS
   **********************************/
  const mountDefaultVariableMenuOptionSections = () => {
    let options: VariableMenuOption[] = [
      ...categories?.reduce((acc, category) => {
        acc.push({
          ...category,
          variables: variables.filter((variable) => variable.category === category.type),
        })
        return acc
      }, [] as VariableMenuOption[]),
    ]

    const parsedSections = sections.map((section) => {
      return {
        label: section.label,
        options: options.filter((option) => {
          return section.categories?.includes?.(option.variables?.[0]?.category!)
        }),
      }
    })

    return {
      sections: parsedSections,
      totalOptionsCount: options.length,
    }
  }

  const variableMenuOptionSections = mountDefaultVariableMenuOptionSections()

  const mountVariableMenuOptionSections = (dto: {
    variablesContext: {
      nodeId: string
      nodeType: CategoryEnum
    }[]
    categoryWithVariablesAndCustomFieldsRecord: typeof CategoryWithVariablesRecord
    // triggersContext: WorkflowTriggerItem[]
  }) => {
    // CONTEXT
    let contextSection: {
      label: string
      options: VariableMenuOption[]
    }[] = []

    let options: VariableMenuOption[] =
      dto.variablesContext
        ?.map((variable) => {
          const categoryWithVariables = dto.categoryWithVariablesAndCustomFieldsRecord[variable.nodeType]

          return {
            reference: variable.nodeId,
            ...categoryWithVariables,
            variables:
              categoryWithVariables?.variables?.map((categoryVariable) => {
                return {
                  ...categoryVariable,
                  type: categoryVariable.type,
                }
              }) || [],
          }
        })
        ?.filter((option) => option.variables?.length > 0) || []
    contextSection = [
      {
        label: "Contexto",
        options,
      },
    ]

    return {
      sections: [...contextSection, ...variableMenuOptionSections.sections],
      totalOptionsCount: options.length + variableMenuOptionSections.totalOptionsCount,
    }
  }

  return {
    actions,
    categories,
    variables,
    ActionRecord,
    CategoryRecord,
    VariableRecord,
    Fields,
    CategoryWithVariablesRecord,
    variableMenuOptionSections,
    mountVariableMenuOptionSections,
  }
}

/**********************************
 * EXPORTS
 **********************************/
export const WorkflowDefs = {
  trigger: WorkflowTriggerDefs,
  ...mountWorkflowDefs(),
}
