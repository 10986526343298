import React from "react"
import { Row, Select, SelectProps } from "antd"
import ColorEmojiIcon from "components-old/forms/ColorEmojiIcon"
import { useStoreState } from "hooks"
import CustomSelect from "components-old/forms/CustomSelect"

interface GroupsSelectProps extends SelectProps<any> {
  ungrouped?: boolean
  beforeOption?: React.ReactNode
}

function GroupsSelect(props: GroupsSelectProps) {
  const { all: groups } = useStoreState((state) => state.groups)

  return (
    <CustomSelect
      allowClear
      showSearch
      // optionFilterProp="children"
      // filterOption={(input, option: any) => {
      //     return (
      //         option?.children?.props.children?.[1]
      //             ?.toLowerCase()
      //             .indexOf(input.toLowerCase()) >= 0
      //     )
      // }}
      {...props}
    >
      {props.beforeOption}
      {props.ungrouped && (
        <Select.Option value={0} key={0} data-search="Não atribuído">
          <div className="flex gap-2 flex-nowrap truncate h-full items-center">
            <i className="fal fa-folder-times w-5" />
            <span className="truncate">Sem grupo</span>
          </div>
        </Select.Option>
      )}
      {groups.map((group: any) => (
        <Select.Option value={group.id} key={group.id} data-search={group.name}>
          <Row align="middle" style={{ flexFlow: "nowrap" }}>
            <ColorEmojiIcon
              icon={group.icon}
              iconStyle={{
                marginRight: 10,
              }}
              size={16}
            />
            {group.name}
          </Row>
        </Select.Option>
      ))}
    </CustomSelect>
  )
}

export default GroupsSelect
